/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { KTIcon } from '../../../helpers'

type settingsModel = {
  id: number;
  key: string;
  type: string;
  value: string;
}
type Props = {
  className: string
  settings: settingsModel[];
  editSetiings: (setting : settingsModel)=>void;
}

const TablesSettings: React.FC<Props> = ({ className, settings, editSetiings }) => {
  return (
    <div className={`card ${className}`}>
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Settings</span>
        </h3>
      </div>
      <div className='card-body py-3'>
        <div className='table-responsive'>
          <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
            <thead>
              <tr className='fw-bold text-muted'>
                <th className='min-w-120px'>Key</th>
                <th className='min-w-140px'>Type</th>
                <th className='min-w-150px'>Value</th>
                <th className='min-w-100px text-end'>Actions</th>
              </tr>
            </thead>
            <tbody>
              {settings && settings.map((setting) => (
                <tr>
                  <td>
                    <span className='text-muted fw-semibold text-muted d-block fs-7'>{setting.key}</span>
                  </td>
                  <td>
                    <span className='text-muted fw-semibold text-muted d-block fs-7'>{setting.type}</span>
                  </td>
                  <td>
                    <span className='text-muted fw-semibold text-muted d-block fs-7'>{setting.value}</span>
                  </td>
                  <td className='text-end'>
                    <button
                      className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                      onClick={()=>editSetiings(setting)}
                    >
                      <KTIcon iconName='pencil' className='fs-3' />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export { TablesSettings }
