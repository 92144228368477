import { BiTargetLock } from 'react-icons/bi';
import BfAccordion from '../bf-accordion/BfAccordion';
import QusAns from '../qus-ans/QusAns';
import './moresection.scss'

type QusAns = {
    icon: React.ReactElement;
    title: string;
    description: string;
}

type target = {
    title: string;
    description: string;
}

interface MoreSectionProps {
    qusAnsOne: QusAns[];
    qusAnsTow: QusAns[];
    targets: target[];
}

export default function MoreSection({ qusAnsOne, qusAnsTow, targets }: MoreSectionProps) {
    return (
        <section className="more_section">
            <div className="container">
                <h2>And there’s <span>more</span></h2>
                <div className="row">
                    <div className="col-12 col-md-4">
                        <ul>
                            {qusAnsOne && qusAnsOne.map((qusAns, index) => (
                                <QusAns
                                    key={index}
                                    icon={qusAns.icon}
                                    title={qusAns.title}
                                    description={qusAns.description}
                                />
                            ))}
                        </ul>
                    </div>
                    <div className="col-12 col-md-4">
                        <ul>
                            {qusAnsTow && qusAnsTow.map((qusAns, index) => (
                                <QusAns
                                    key={index}
                                    icon={qusAns.icon}
                                    title={qusAns.title}
                                    description={qusAns.description}
                                />
                            ))}
                        </ul>
                    </div>
                    <div className="col-12 col-md-4">
                        <BfAccordion items={targets} accordionClass={'more_accordion'} icon={<BiTargetLock/>}/>
                    </div>
                </div>
            </div>
        </section>
    )
}
