import './reviewvideo.scss'

interface ReviewVideoProps {
    thumbnail: string;
    videoUrl: string;
    userName: string;
    country: string;
    videoTitle: string;
    openModal: ()=>void;
    modalVideo: (url : string)=>void;
}

export default function ReviewVideo({thumbnail, videoUrl, userName, country, videoTitle, openModal, modalVideo}: ReviewVideoProps) {
    return (
        <div className="card video_review">
            <div className="head">
                <div className="imgbox">
                    <img
                        src={thumbnail}
                        alt="Picture of the author"
                    />
                </div>
                <div className="play_button_box">
                    <button 
                        className="video_play_button" 
                        onClick={()=>{
                            openModal();
                            modalVideo(videoUrl);
                        }}
                    >
                        <span></span>
                    </button>
                </div>
            </div>
            <div className="body">
                <h4>{userName}</h4>
                <h5>{country}</h5>
                <p>{videoTitle}</p>
            </div>
        </div>
    )
}
