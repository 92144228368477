import BlogCard from '../blog-card/BlogCard';
import './blogsection.scss'

type blog = {
    imgUrl: string,
    title: string,
    description: string,
    link: string,
}

interface BlogSectionProps{
    blogs: blog[];
}

export default function BlogSection({blogs}: BlogSectionProps) {
    return (
        <section className="blog_section">
            <div className="container">
                <div className="row gy-4">
                    {blogs && blogs.map((blog, index)=>(
                        <BlogCard 
                            key={index} 
                            imgUrl={blog.imgUrl} 
                            title={blog.title} 
                            description={blog.description} 
                            link={blog.link}
                        />
                    ))}
                </div>
            </div>
        </section>
    )
}
