import {FC, lazy, Suspense} from 'react'
import {Navigate, Route, Routes} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {MenuTestPage} from '../pages/MenuTestPage'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
import LandingPage from '../pages/public/landing-page/LandingPage'
import AboutPage from '../pages/public/about/AboutPage'
import CoursesPage from '../pages/public/courses/CoursesPage'
import CoursePage from '../pages/public/courses/course/CoursePage'
import BlogsPage from '../pages/public/blogs/BlogsPage'
import BlogPage from '../pages/public/blogs/blog/BlogPage'
import Settings from '../pages/admin/settings/Settings'
import Permission from '../pages/admin/permission/Permission'
import CourseList from '../pages/admin/course/CourseList'
import EventPage from '../pages/public/event/EventPage'
import SubscriptionPage from '../pages/public/subscription/SubscriptionPage'
import ContactPage from '../pages/public/contact/ContactPage'
import LeasonPage from '../pages/public/courses/leason/LeasonPage'
import CartPage from '../pages/public/cart/CartPage'

const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))

  return (
    <Routes>
      {/* public pages */}
      <Route path='/landing-page' element={<LandingPage />} />
      <Route path='/about' element={<AboutPage />} />
      <Route path='/courses/:slug' element={<CoursesPage />} />
      <Route path='/courses/:slug/:id' element={<CoursePage/>} />
      <Route path='/courses/:slug/:id/leason' element={<LeasonPage/>} />
      <Route path='/blogs' element={<BlogsPage />} />
      <Route path='/blogs/:id' element={<BlogPage />} />
      <Route path='/events' element={<EventPage />} />
      <Route path='/subscription' element={<SubscriptionPage />} />
      <Route path='/contact' element={<ContactPage />} />
      <Route path='/cart' element={<CartPage />} />


      <Route element={<MasterLayout/>}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />

        {/* admin pages */}
        <Route path='/admin/permission' element={<Permission />} />
        <Route path='/admin/settings' element={<Settings />} />
        <Route path='/admin/course-list' element={<CourseList />} />

        <Route path='builder' element={<BuilderPageWrapper />} />
        <Route path='menu-test' element={<MenuTestPage />} />

        {/* Lazy Modules */}
        <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
