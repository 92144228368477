import './aboutsection.scss'
import Button from '../button/Button'

export default function About() {
    return (
        <section className="about_section">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-md-6">
                        <div className="about_img">
                            <div className="imgbox">
                                <img
                                    src="/about.jpg"
                                    alt="Picture of the author"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-6">
                        <div className="about_text">
                            <h2>Meet Pilar Guerrero, your <span>course teacher</span></h2>
                            <p>
                                Lorem Ipsum is simply dummy text of the printing and typesetting
                                industry. Lorem Ipsum has been the industry's standard dummy text
                                ever since the 1500s, when an unknown printer took a galley of type
                                and scrambled it to make a type specimen book. It has survived not only five centuries,
                            </p>
                            <Button title={'About  the teacher'} link={'/'}/>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
