/* eslint-disable react/jsx-no-target-blank */
import { useIntl } from 'react-intl'
import { AsideMenuItemWithSubMain } from './AsideMenuItemWithSubMain'
import { AsideMenuItemWithSub } from './AsideMenuItemWithSub'
import { AsideMenuItem } from './AsideMenuItem'

export function AsideMenuMain() {
  const intl = useIntl()
  return (
    <>
      <AsideMenuItem
        to='/dashboard'
        title={intl.formatMessage({ id: 'MENU.DASHBOARD' })}
        fontIcon='bi-bar-chart-line'
        bsTitle={intl.formatMessage({ id: 'MENU.DASHBOARD' })}
        className='py-3'
      />
      
      <AsideMenuItem
        to='/builder'
        title='Layout Builder'
        bsTitle='Layout Builder'
        fontIcon='bi-gear'
        className='py-3'
      />

      <AsideMenuItemWithSubMain
        to='/user-management'
        title='User Management'
        fontIcon='bi-people'
        bsTitle='User Management'
      >
        <AsideMenuItem
          to='/admin/permission'
          title='Permission'
          bsTitle='Permission'
          hasBullet={true}
        />

      </AsideMenuItemWithSubMain>

      <AsideMenuItemWithSubMain
        to='/Course'
        title='Course'
        fontIcon='bi-mortarboard'
        bsTitle='Course'
      >
        <AsideMenuItem
          to='/admin/course-list'
          title='Course list'
          bsTitle='Course list'
          hasBullet={true}
        />

      </AsideMenuItemWithSubMain>
    </>
  )
}
