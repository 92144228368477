import axios from 'axios'
import { AuthModel, UserModel } from './_models'

const API_URL = process.env.REACT_APP_API_URL

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/profile`
export const REFRESH_TOKEN = `${API_URL}/refresh-token`
export const LOGIN_URL = `${API_URL}/login`
export const REGISTER_URL = `${API_URL}/register`
export const REQUEST_PASSWORD_URL = `${API_URL}/forgot_password`

// Server should return AuthModel
export async function login(email: string, password: string) {
  const response = await axios.post(LOGIN_URL, {
    email,
    password,
  });
  const userData = response.data.data.user;

  const refresh = await axios.get(REFRESH_TOKEN, {
    headers: {
      'Authorization': 'Bearer Bearer ' + response.data.data.token.access_token
    }
  });

  userData.token_expires_at = refresh.data.data.token.expires_at;

  const userAuth : AuthModel ={
    data: userData,
    api_token: refresh.data.data.token.access_token,
    refreshToken: refresh.data.data.token.access_token
  }

  return userAuth;
}

// Server should return AuthModel
export function register(
  email: string,
  firstname: string,
  lastname: string,
  password: string,
  birthDate: string,
  gender: string,
  profileImage
) {
  return axios.post(REGISTER_URL, {
    name: firstname + " " + lastname,
    email,
    first_name: firstname,
    last_name: lastname,
    password,
    birth: birthDate,
    gender,
    profile_image: profileImage
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{ result: boolean }>(REQUEST_PASSWORD_URL, {
    email,
  })
}

export function getUserByToken(token: string) {
  return axios.get<UserModel>(GET_USER_BY_ACCESSTOKEN_URL);
}
