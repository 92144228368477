"use client"
import { useState } from 'react';
import BlogMenu from '../blog-menu/BlogMenu'
import './blogwrapper.scss'

type recentPost = {
    imgUrl: string,
    title: string,
}
type blogCategory = {
    title: string,
    totalBlogs: number,
}

interface BlogWrapperProps{
    recentPosts: recentPost[];
    blogCategories: blogCategory[];
    children: React.ReactElement;
}

export default function BlogWrapper({recentPosts, blogCategories, children}: BlogWrapperProps) {
    const [menuStatus, setMenuStatus] = useState(false);
    return (
        <section className={`blog_wrapper ${menuStatus && 'menu_status'}`}>
            <BlogMenu 
                recentPosts={recentPosts} 
                blogCategories={blogCategories} 
                handleMenu={()=>setMenuStatus(!menuStatus)}
            />
            <div className="blog_content">
                {children}
            </div>
        </section>
    )
}
