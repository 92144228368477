import { useState } from 'react';
import './navbar.scss'
import { FiMenu } from 'react-icons/fi'
import NavLink from '../nav-link/NavLink';
import NavDropdown from '../nav-dropdown/NavDropdown';
import { Link } from 'react-router-dom';

const menuList = [
    {
        title: 'Home',
        url: '/',
    },
    {
        title: 'About',
        url: '/about',
    },
    {
        title: 'Special Event',
        url: '/Events',
    },
    {
        title: 'Blog',
        url: '/blogs',
    },
    {
        title: 'Subscription',
        url: '/subscription',
    },
    {
        title: 'Contact us',
        url: '/contact',
    },
    {
        title: 'Cart',
        url: '/cart',
    },
]

interface NavbarProps {
    isUser: boolean;
}

export default function Navbar({ isUser }: NavbarProps) {
    const [isMenuActive, setIsMenuActive] = useState(false);
    return (
        <nav className="section_navbar">
            <div className="container-xl">
                <Link to="/landing-page" className="logo">BestFluency</Link>
                <FiMenu className="hamburger" onClick={() => setIsMenuActive(!isMenuActive)} />

                <ul className={`nav_menu ${isMenuActive && 'active'}`}>
                    {menuList && menuList.map((link, index) => (
                        <li key={index}>
                            {link.url && <NavLink title={link.title} url={link.url}/>}
                        </li>
                    ))}
                    <li>
                        {isUser ?
                            <Link to="/dashboard" className="register_btn_sm">Dashboard</Link>
                            :
                            <Link to="/auth" className="register_btn_sm">Login</Link>
                        }
                    </li>
                </ul>
                {isUser ?
                    <Link to="/dashboard" className="register_btn_lg">Dashboard</Link>
                    :
                    <Link to="/auth" className="register_btn_lg">Login</Link>
                }
            </div>
        </nav>
    )
}
