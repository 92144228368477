import { Link } from "react-router-dom";


interface NavLinkProps {
    title: string;
    url: string;
}

export default function NavLink({title, url}:NavLinkProps) {
  return (
    <Link to={url}>{title}</Link>
  )
}
