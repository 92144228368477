import { Link } from 'react-router-dom';
import './coursecard.scss'
import { GoUnlock, GoLock } from 'react-icons/go';

interface CourseCardProps {
    courseLink: string;
    courseImgUrl: string;
    lessons: string;
    type: string;
    title: string;
    description: string;
    mentorImgUrl: string;
    mentorName: string;
}

export default function CourseCard({
    courseLink,
    courseImgUrl,
    lessons,
    type, 
    title, 
    description, 
    mentorImgUrl, 
    mentorName 
}: CourseCardProps) {
    return (
        <Link to={courseLink}>
            <div className="card">
                <div className="head">
                    <div className="imgbox">
                        <img
                            src={courseImgUrl}
                            alt="img"
                        />
                    </div>
                    <ul className="tag_list">
                        <li><i className='bx bx-book-open'></i>{lessons}</li>
                        {type == 'Free' && (<li className="bg_aqua"><GoUnlock/> Free </li>)}
                        {type == 'Premium' && (<li className="bg_aqua"><GoLock/> Premium </li>)}
                    </ul>
                </div>
                <div className="body">
                    <h2>{title}</h2>
                    <p>{description.length > 120 ? description.slice(0, 120) + '...' : description}</p>
                    <ul className="tag_list">
                        <li className="img_tag">
                            <div className="imgbox">
                                <img
                                    src={mentorImgUrl}
                                    alt="img"
                                />
                            </div>
                            {mentorName}
                        </li>
                        <li><i className='bx bx-book-open'></i>{lessons}</li>
                    </ul>
                </div>
            </div>
        </Link>
    )
}
