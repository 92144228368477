import './../../../../styles/reset.scss';
import './../../../../styles/responsive.scss';
import './../../../../styles/contact.scss';
import Footer from '../../../components/footer/Footer';
import Navbar from '../../../components/navbar/Navbar';
import { useAuth } from '../../../modules/auth';
import { FaFacebookF } from 'react-icons/fa';

export default function ContactPage() {
    const { currentUser } = useAuth();
    return (
        <>
            <Navbar isUser={currentUser ? true : false} />
            <section className='contact_section'>
                <div className='container'>
                    <div className='row'>
                        <div className="heading_box">
                            <h2 className="heading">Get in touch with us</h2>
                            <p className="pera">
                                Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                                Dolore voluptates facilis, qui
                            </p>
                        </div>

                        <div className="title_box">
                            <h3 className="heading">Contact us</h3>
                        </div>

                        <div className="row">
                            <div className="col-12 col-md-4">
                                <div className="contact_box">
                                    <h2 className="heading">Location</h2>
                                    <p className="pera">
                                        Lorem ipsum dolor sit amet consectetur,
                                        adipisicing elit. At
                                    </p>
                                </div>
                                <div className="contact_box">
                                    <h2 className="heading">Location</h2>
                                    <p className="pera">
                                        Lorem ipsum dolor sit amet consectetur,
                                        adipisicing elit. At
                                    </p>
                                </div>
                                <ul className="socials">
                                    <li>
                                        <a href="">
                                            <FaFacebookF />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="">
                                            <FaFacebookF />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="">
                                            <FaFacebookF />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="">
                                            <FaFacebookF />
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-12 col-md-8">
                                <div className="pera">
                                    Lorem ipsum dolor sit amet consectetur
                                    adipisicing elit. Aliquam eveniet
                                </div>
                                <form action="/">
                                    <input type="text" className="input_box half_width" placeholder="Your name" />
                                    <input type="text" className="input_box half_width" placeholder="Your name" />
                                    <input type="text" className="input_box" placeholder="Your name" />
                                    <textarea name="" className="input_box" placeholder="Comment"></textarea>
                                    <button type="submit">Get in touch</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}
