import './bfstep.scss'

interface BfStepsprops{
    icon: React.ReactNode,
    title: string,
    description: string,
}

export default function BfStep({icon, title, description}: BfStepsprops) {
    return (
        <div className="col-12 col-md-6">
            <div className="steps_card card">
                <div className="head">
                    <div className="icon_box">
                        {icon}
                    </div>
                </div>
                <div className="body">
                    <h3>{title}</h3>
                    <p>{description}</p>
                </div>
            </div>
        </div>
    )
}
