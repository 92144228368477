import React from 'react'
import './../../../../../styles/reset.scss';
import './../../../../../styles/blog.scss';
import './../../../../../styles/responsive.scss';
import { BiLogoFacebook } from 'react-icons/bi';
import { BsFillCheckCircleFill } from 'react-icons/bs';
import { AiFillYoutube, AiOutlineTwitter } from 'react-icons/ai';
import Navbar from '../../../../components/navbar/Navbar';
import Footer from '../../../../components/footer/Footer';
import { useAuth } from '../../../../modules/auth';
import RelatedBlogs from '../../../../components/related-blogs/RelatedBlogs';
import PostTime from '../../../../components/post-time/PostTime';

export default function BlogPage() {
    const { currentUser } = useAuth();
    return (
        <>
            <Navbar isUser={currentUser ? true : false} />

            <section className="blog_details_section">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-6">
                            <div className="left_content">
                                <div className="imgbox">
                                    <img src="/blog-details.jpg" alt="" />
                                </div>
                                
                                <PostTime/>

                                <div className="text_box_1">
                                    <h2>
                                        Lorem Ipsum is simply dummy text of
                                        the printing and typesetting
                                    </h2>

                                    <p>
                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                        Lorem Ipsum has been the industry's standard dummy text ever since the
                                        1500s, when an unknown printer took a galley of type and scrambled it to make
                                        a type specimen book. It has survived not only five centuries, but also the leap
                                        into electronic typesetting,
                                    </p>
                                </div>

                                <div className="annotation">
                                    <div className="imgbox">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="34" height="26" viewBox="0 0 34 26" fill="none">
                                            <path d="M3.61641 23.4501C1.78308 21.4335 0.683076 19.2335 0.683076 15.5668C0.683076 9.15013 5.26641 3.4668 11.6831 0.533462L13.3331 2.91679C7.28307 6.21679 5.99974 10.4335 5.63307 13.1835C6.54974 12.6335 7.83308 12.4501 9.11641 12.6335C12.4164 13.0001 14.9831 15.5668 14.9831 19.0501C14.9831 20.7001 14.2497 22.3501 13.1497 23.6335C11.8664 24.9168 10.3997 25.4668 8.56641 25.4668C6.54974 25.4668 4.71641 24.5501 3.61641 23.4501ZM21.9497 23.4501C20.1164 21.4335 19.0164 19.2335 19.0164 15.5668C19.0164 9.15013 23.5997 3.4668 30.0164 0.533462L31.6664 2.91679C25.6164 6.21679 24.3331 10.4335 23.9664 13.1835C24.8831 12.6335 26.1664 12.4501 27.4497 12.6335C30.7497 13.0001 33.3164 15.7501 33.3164 19.0501C33.3164 20.7001 32.5831 22.3501 31.4831 23.6335C30.1997 24.9168 28.7331 25.4668 26.8997 25.4668C24.8831 25.4668 23.0497 24.5501 21.9497 23.4501Z" fill="#A6E7C2" />
                                        </svg>
                                    </div>
                                    <p>“ Lorem Ipsum is simply dummy text of the printing and typesetting</p>
                                </div>

                                <div className="text_box_2">
                                    <h2>Lorem Ipsum is simply dummy</h2>
                                    <p>
                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                        Lorem Ipsum has been the industry's standard dummy text ever since the
                                        1500s
                                    </p>
                                </div>

                                <ul className="targets">
                                    <li>
                                        <BsFillCheckCircleFill />
                                        Lorem Ipsum is simply dummy
                                    </li>
                                    <li>
                                        <BsFillCheckCircleFill />
                                        Lorem Ipsum is simply dummy
                                    </li>
                                    <li>
                                        <BsFillCheckCircleFill />
                                        Lorem Ipsum is simply dummy
                                    </li>
                                    <li>
                                        <BsFillCheckCircleFill />
                                        Lorem Ipsum is simply dummy
                                    </li>
                                    <li>
                                        <BsFillCheckCircleFill />
                                        Lorem Ipsum is simply dummy
                                    </li>
                                    <li>
                                        <BsFillCheckCircleFill />
                                        Lorem Ipsum is simply dummy
                                    </li>
                                </ul>

                                <div className="text_box_3">
                                    <p>
                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                        Lorem Ipsum has been the industry's standard dummy text ever since the
                                        1500s, when an unknown printer took a galley of type and scrambled it to make
                                        a type specimen book. It has survived not only five centuries, but also the leap
                                        into electronic typesetting,
                                    </p>
                                </div>

                                <div className="tag_container">
                                    <div className="tag_box">
                                        <h3>Tags:</h3>
                                        <ul className="category">
                                            <li>IELTS Essentials</li>
                                            <li>Test Prep</li>
                                        </ul>
                                    </div>
                                    <div className="tag_box">
                                        <h3>Tags:</h3>
                                        <ul className="social">
                                            <li><a href=""><BiLogoFacebook /></a></li>
                                            <li><a href=""><AiOutlineTwitter /></a></li>
                                            <li><a href=""><BiLogoFacebook /></a></li>
                                            <li><a href=""><AiFillYoutube /></a></li>
                                        </ul>
                                    </div>
                                </div>

                                <div className="review_box">
                                    <div className="imgbox">
                                        <img src="/review.jpg" alt="" />
                                    </div>
                                    <div className="review_text_box">
                                        <h4>Learning, friendship </h4>
                                        <h3>Learning, friendship and fun <br /> for everyone </h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="right_content">
                                <div className="trainor_box">
                                    <div className="trainor_img">
                                        <div className="imgbox">
                                            <img src="/trainor.jpg" alt="" />
                                        </div>
                                    </div>
                                    <div className="trainor_details">
                                        <h4>Learning, friendship </h4>
                                        <h3>Pilar Guerrero</h3>
                                        <p>
                                            Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                            Lorem Ipsum has been the industry's standard dummy text
                                        </p>
                                    </div>
                                </div>
                                <ul className="links">
                                    <li>
                                        <h3>Join the community</h3>
                                        <a href="../index.html#joinCommunity">Myxybq94Ev4J8uiYOJTn8b/bestfluency?type=design&node-id=0-1&mode</a>
                                    </li>
                                    <li>
                                        <h3>Free courses</h3>
                                        <a href="../index.html#freeCourse">Myxybq94Ev4J8uiYOJTn8b/bestfluency?type=design&node-id=0-1&mode</a>
                                    </li>
                                    <li>
                                        <h3>Premium courses</h3>
                                        <a href="../index.html#premiumCourse">Myxybq94Ev4J8uiYOJTn8b/bestfluency?type=design&node-id=0-1&mode</a>
                                    </li>
                                </ul>

                                <RelatedBlogs/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer/>
        </>
    )
}
