/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'

import { useAuth } from '../../../../app/modules/auth';
import { getRequest } from '../../../../helpers/axiosHelpers';
import CheckBox from '../../../../app/components/check-box/CheckBox';

type roleModel = {
  id: number;
  name: string;
}
type permissionModel = {
  id: number;
  name: string;
  roles: any[];
}

type Props = {
  className: string
  permissions: permissionModel[];
  roles: roleModel[];
}

const TablesPermission: React.FC<Props> = ({ className, permissions, roles }) => {
  

  return (
    <div className={`card ${className}`}>
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Permission</span>
        </h3>
      </div>

      <div className='card-body py-3'>
        <div className='table-responsive'>

          <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3 table-striped'>
            <thead>
              <tr className='fw-bold text-muted'>
                <th className='min-w-150px'>Permissions</th>
                {roles && roles.map((role) => (
                  <th className='min-w-140px'>{role.name}</th>
                ))}
              </tr>
            </thead>

            <tbody>
              {permissions && permissions.map((permission) => (
                <tr>
                  <td>
                    <span className='text-dark fw-bold text-hover-primary fs-6 text-capitalize'>
                      {permission.name}
                    </span>
                  </td>
                  {roles && roles.map((role) => (
                    <td>
                      <div className='form-check form-check-sm form-check-custom form-check-solid'>
                        <CheckBox 
                          permission={permission} 
                          role={role} 
                        />
                      </div>
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export { TablesPermission }
