import './testimonialslider.scss'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';
import ReviewComment from '../review-comment/ReviewComment';
import ReviewVideo from '../review-video/ReviewVideo';
import { useState } from 'react';
import Modal from '../modal/Modal';
import ReactPlayer from 'react-player'

type ReviewComment = {
    profileImg: string;
    comment: string;
    userName: string;
    userTitle: string;
    ratings: number;
}
type ReviewVideo = {
    thumbnail: string;
    videoUrl: string;
    userName: string;
    country: string;
    videoTitle: string;
}

interface TestimonialSliderProps {
    reviewComents: ReviewComment[];
    reviewVideos: ReviewVideo[];
}

export default function TestimonialSlider({ reviewComents, reviewVideos }: TestimonialSliderProps) {
    const [modalStatus, setModalStatus] = useState<boolean>(false);
    const [isModalOpend, setModalOpend] = useState<boolean>(false);
    const [modalVideoUrl, setmodalVideoUrl] = useState<string>("");
    return (
        <>
            <section className="testimonial_section">
                <div className="container">
                    <h3>Testimonial</h3>
                    <h2>Students testimonial</h2>
                    <p>Lorem Ipsum is simply dummy text of the printing and <br /> typesetting industry.</p>
                    <div className="swiper_container">
                        <Swiper
                            modules={[Pagination]}
                            slidesPerView={1}
                            spaceBetween={30}
                            pagination={{
                                clickable: true,
                            }}
                            breakpoints={{
                                540: {
                                    slidesPerView: 2,
                                    spaceBetween: 50,
                                },
                                912: {
                                    slidesPerView: 3,
                                    spaceBetween: 50,
                                },
                            }}
                        >
                            {reviewComents && reviewComents.map((reviewComent, index) => (
                                <SwiperSlide key={index}>
                                    <ReviewComment
                                        profileImg={reviewComent.profileImg}
                                        comment={reviewComent.comment}
                                        userName={reviewComent.userName}
                                        userTitle={reviewComent.userTitle}
                                        ratings={reviewComent.ratings}
                                    />
                                </SwiperSlide>
                            ))}
                            {reviewVideos && reviewVideos.map((reviewVideo, index) => (
                                <SwiperSlide key={index}>
                                    <ReviewVideo
                                        thumbnail={reviewVideo.thumbnail}
                                        videoUrl={reviewVideo.videoUrl}
                                        userName={reviewVideo.userName}
                                        country={reviewVideo.country}
                                        videoTitle={reviewVideo.videoTitle}
                                        openModal={() => {
                                            setModalStatus(!modalStatus);
                                            setModalOpend(true);
                                        }} 
                                        modalVideo={(url: string)=>{
                                            setmodalVideoUrl(url);
                                        }}                                    
                                    />
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </div>
                </div>
            </section>
            {isModalOpend &&
                <Modal status={modalStatus} closeModal={() => setModalStatus(!modalStatus)}>
                    <ReactPlayer
                        url={modalVideoUrl}
                        controls={true}
                        width={'100%'}
                        height={'100%'}
                        playing={modalStatus}
                    />
                </Modal>
            }
        </>
    )
}
