import Footer from '../../../../components/footer/Footer';
import LeasonDetails from '../../../../components/leason-details/LeasonDetails';
import Navbar from '../../../../components/navbar/Navbar';
import { useAuth } from '../../../../modules/auth';
import './../../../../../styles/reset.scss';
import './../../../../../styles/responsive.scss';

const classes = [
    {
        value: 'Elementary A2',
        title: 'Elementary A2',
    },
    {
        value: 'Elementary A2',
        title: 'Elementary A2',
    },
    {
        value: 'Elementary A2',
        title: 'Elementary A2',
    },
    {
        value: 'Elementary A2',
        title: 'Elementary A2',
    },
]

const lessons = [
    {
        imgUrl: '/lessons1.png',
        title: 'Amazing to meet with friends',
        description: 'Lorem Ipsum Lorem Ipsum'
    },
    {
        imgUrl: '/lessons2.png',
        title: 'Amazing to meet with friends',
        description: 'Lorem Ipsum Lorem Ipsum'
    },
    {
        imgUrl: '/lessons3.png',
        title: 'Amazing to meet with friends',
        description: 'Lorem Ipsum Lorem Ipsum'
    },
    {
        imgUrl: '/lessons4.png',
        title: 'Amazing to meet with friends',
        description: 'Lorem Ipsum Lorem Ipsum'
    },
    {
        imgUrl: '/lessons5.png',
        title: 'Amazing to meet with friends',
        description: 'Lorem Ipsum Lorem Ipsum'
    },
    {
        imgUrl: '/lessons6.png',
        title: 'Amazing to meet with friends',
        description: 'Lorem Ipsum Lorem Ipsum'
    },
]


export default function LeasonPage() {
    const { currentUser, auth } = useAuth();
    return (
        <>
            <Navbar isUser={currentUser ? true : false} />
            <LeasonDetails classes={classes} lessons={lessons}/>
            <Footer/>
        </>
    )
};