import './footer.scss'
import { BiSolidMap, BiSolidPhoneCall } from 'react-icons/bi';
import { MdEmail} from 'react-icons/md';
import ContactBox from '../contact-box/ContactBox';
import { AiOutlineGooglePlus, AiOutlineTwitter } from 'react-icons/ai';
import { FaFacebookF } from 'react-icons/fa';
import { FiSearch } from 'react-icons/fi';
import { Link } from 'react-router-dom';

const contacts = [
    {
        icon: <BiSolidMap />,
        title: "Find us",
        contact: "1010 Avenue, sw 54321, chandigarh",
    },
    {
        icon: <BiSolidPhoneCall />,
        title: "Call us",
        contact: "9876543210 0",
    },
    {
        icon: <MdEmail />,
        title: "Mail us",
        contact: "mail@info.com",
    },
]

export default function Footer() {
    return (
        <footer className="footer">
            <div className="container">
                <div className="row">
                    {contacts && contacts.map((contact, index)=>(
                        <ContactBox 
                            key={index} 
                            icon={contact.icon} 
                            title={contact.title} 
                            contact={contact.contact}
                        />
                    ))}
                </div>
                <div className="line"></div>
                <div className="row">
                    <div className="col-12 col-md-4">
                        <div className="about_box">
                            <h2 className="logo">BestFluency</h2>
                            <p>
                                Lorem ipsum dolor sit amet, consec tetur adipisicing
                                elit, sed do eiusmod tempor incididuntut consec tetur
                                adipisicing elit,Lorem ipsum dolor sit amet.
                            </p>
                            <div className="follow_box">
                                <h3>Follow us</h3>
                                <ul className="social">
                                    <Link to="">
                                        <li className="facebook">
                                            <AiOutlineTwitter/>
                                        </li>
                                    </Link>
                                    <Link to="">
                                        <li className="twiter">
                                            <FaFacebookF />
                                        </li>
                                    </Link>
                                    <Link to="">
                                        <li className="google">
                                            <AiOutlineGooglePlus />
                                        </li>
                                    </Link>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-4">
                        <div className="link_box">
                            <h2>Links</h2>
                            <ul>
                                <Link to=""><li>Home</li></Link>
                                <Link to=""><li>About</li></Link>
                                <Link to=""><li>Free Course</li></Link>
                                <Link to=""><li>Premium Courses</li></Link>
                                <Link to=""><li>Course Details</li></Link>
                                <Link to=""><li>Blogs</li></Link>
                                <Link to=""><li>Blog Details</li></Link>
                                <Link to=""><li>Subscription</li></Link>
                            </ul>
                        </div>
                    </div>
                    <div className="col-12 col-md-4">
                        <div className="subscribe_box">
                            <h2>Subscribe</h2>
                            <p>
                                Don’t miss to subscribe to our new feeds,
                                kindly fill the form below.
                            </p>

                            <div className="inputbox">
                                <input type="text" placeholder="Email" />
                                <button><FiSearch/></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}
