import './recentpost.scss'

interface RecentPostProps{
    imgUrl: string;
    title: string;
}

export default function RecentPost({imgUrl, title}: RecentPostProps) {
    return (
        <li className='recent_posts_item'>
            <div className="imgbox">
                <img
                    src={imgUrl}
                    width={500}
                    height={500}
                    alt="Picture of the author"
                />
            </div>
            <p>{title}</p>
        </li>
    )
}
