import './rating.scss'
import {AiFillStar, AiOutlineStar} from 'react-icons/ai'

interface RatingProps {
    ratings : number;
}

export default function Rating({ratings}:RatingProps) {
    return (
        <ul className="ratings">
            <li>{ratings >= 1 ? <AiFillStar /> : <AiOutlineStar />}</li>
            <li>{ratings >= 2 ? <AiFillStar /> : <AiOutlineStar />}</li>
            <li>{ratings >= 3 ? <AiFillStar /> : <AiOutlineStar />}</li>
            <li>{ratings >= 4 ? <AiFillStar /> : <AiOutlineStar />}</li>
            <li>{ratings >= 5 ? <AiFillStar /> : <AiOutlineStar />}</li>
        </ul>
    )
}
