import React, { useEffect, useState } from 'react'
import { TablesPermission } from '../../../../_metronic/partials/widgets/tables/TablesPermission'
import { useAuth } from '../../../modules/auth';
import { getRequest } from '../../../../helpers/axiosHelpers';
import { PageTitle } from '../../../../_metronic/layout/core';

type roleModel = {
    id: number;
    name: string;
}
type permissionModel = {
    id: number;
    name: string;
    roles: any[];
}

export default function Permission() {
    const { auth } = useAuth();
    const [permissions, setPermissions] = useState<permissionModel[]>([])
    const [roles, setRoles] = useState<roleModel[]>([])

    useEffect(() => {
        async function fetchPermission() {
            const url = '/permissions';

            if (auth) {
                try {
                    const response = await getRequest(url, auth.api_token);
                    let permissions: permissionModel[] = [];
                    response.data.data.forEach((permission: any) => {
                        permissions.push({
                            id: permission.id,
                            name: permission.name,
                            roles: permission.roles,
                        });
                    });
                    setPermissions(permissions);
                } catch (error) {
                    console.error(error);
                }
            }
        }
        fetchPermission();

        async function fetchRoles() {
            const url = '/roles';

            if (auth) {
                try {
                    const response = await getRequest(url, auth.api_token);
                    let roles: roleModel[] = [];
                    response.data.data.forEach((role: any) => {
                        roles.push({
                            id: role.id,
                            name: role.name
                        });
                    });
                    setRoles(roles);
                } catch (error) {
                    console.error(error);
                }
            }
        }
        fetchRoles();
    }, []);
    return (
        <>
            <PageTitle breadcrumbs={[]}>Permission</PageTitle>
            {permissions.length
                && roles.length &&
                <TablesPermission
                    className={'permission-table'}
                    permissions={permissions}
                    roles={roles}              
                />
            }
        </>
    )
}
