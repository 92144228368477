import './../../../../styles/reset.scss';
import './../../../../styles/responsive.scss';
import { BiSolidUserPlus } from 'react-icons/bi';
import { BsCheckCircleFill, BsKey, BsPatchQuestionFill } from 'react-icons/bs';
import { FaGlobe } from 'react-icons/fa';
import { SiLevelsdotfyi } from 'react-icons/si';
import Navbar from '../../../components/navbar/Navbar';
import AboutSection from '../../../components/about-section/AboutSection';
import StepsSection from '../../../components/steps-section/StepsSection';
import TestimonialSlider from '../../../components/testimonial-slider/TestimonialSlider';
import FeatureSection from '../../../components/feature-section/FeatureSection';
import MoreSection from '../../../components/more-section/MoreSection';
import Footer from '../../../components/footer/Footer';
import { useAuth } from '../../../modules/auth';


const reviewsComments = [
    {
        profileImg: '/testimonial1.jpg',
        comment: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. printing and typesetting industry.printing and typesetting',
        userName: 'Lorem Ipsum',
        userTitle: 'Lorem Ipsum',
        ratings: 4
    },
    {
        profileImg: '/testimonial2.jpg',
        comment: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. printing and typesetting industry.printing and typesetting',
        userName: 'Lorem Ipsum',
        userTitle: 'Lorem Ipsum',
        ratings: 4
    },
    {
        profileImg: '/testimonial3.jpg',
        comment: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. printing and typesetting industry.printing and typesetting',
        userName: 'Lorem Ipsum',
        userTitle: 'Lorem Ipsum',
        ratings: 4
    },
]

const reviewVideos = [
    {
        thumbnail: "/testimonial4.jpg",
        videoUrl: "/testimonial.mp4",
        userName: "Name",
        country: "Country",
        videoTitle: "Video title goes here",
    },
    {
        thumbnail: "/testimonial5.jpg",
        videoUrl: "/testimonial.mp4",
        userName: "Name",
        country: "Country",
        videoTitle: "Video title goes here",
    },
    {
        thumbnail: "/testimonial6.jpg",
        videoUrl: "/testimonial.mp4",
        userName: "Name",
        country: "Country",
        videoTitle: "Video title goes here",
    },
]

const steps = [
    {
        icon: <BiSolidUserPlus />,
        title: "Create an account",
        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    },
    {
        icon: <FaGlobe />,
        title: "Select language",
        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    },
    {
        icon: <SiLevelsdotfyi />,
        title: "Learn your level",
        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    },
    {
        icon: <BsCheckCircleFill />,
        title: "Ready to start",
        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    },
]

const features = [
    {
        icon: <BsKey/>,
        title: "Course Material",
        description: "Quality teaching, practice materials & methods are crucial to your success.",
        url: "/",
    },
    {
        icon: <BsKey/>,
        title: "Score Estimator",
        description: "A quick test to identify your skill gaps and give you an estimated score range",
        url: "/",
    },
    {
        icon: <BsKey/>,
        title: "Practice Question",
        description: "100s of practice questions available. Self- study has never been easier.",
        url: "/",
    },
    {
        icon: <BsKey/>,
        title: "Regular Live Classes",
        description: "Join engaging sessions with expert instructors for interactive learning",
        url: "/",
    },
    {
        icon: <BsKey/>,
        title: "Mini Mock Tests",
        description: "Just like the real test. Timed the same and scored the same!",
        url: "/",
    },
    {
        icon: <BsKey/>,
        title: "Speaking & Writing Assessments",
        description: "Enhance your speaking and writing with expert assessments.",
        url: "/",
    },
    {
        icon: <BsKey/>,
        title: "One-on-one Expert Tutorials",
        description: "Personalized guidance from skilled tutors for exam success.",
        url: "/",
    },
    {
        icon: <BsKey/>,
        title: "Recorded Classes",
        description: "Online, convenient and specifically designed to prepare you for your test",
        url: "/",
    },
]

const qusAnsOne = [
    {
        icon: <BsPatchQuestionFill/>,
        title: "Who?",
        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    },
    {
        icon: <BsPatchQuestionFill/>,
        title: "What?",
        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    },
    {
        icon: <BsPatchQuestionFill/>,
        title: "Where?",
        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    },
    {
        icon: <BsPatchQuestionFill/>,
        title: "When?",
        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    },
]

const qusAnsTow = [
    {
        icon: <BsPatchQuestionFill/>,
        title: "How?",
        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    },
    {
        icon: <BsPatchQuestionFill/>,
        title: "Why?",
        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    },
    {
        icon: <BsPatchQuestionFill/>,
        title: "How much?",
        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    },
    {
        icon: <BsPatchQuestionFill/>,
        title: "What for?",
        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    },
]

const targets = [
    {
        title: "Our Aims",
        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
    },
    {
        title: "Our Mission",
        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
    },
    {
        title: "Our vision",
        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
    },
    {
        title: "About Us",
        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
    },
    {
        title: "Disclaimer",
        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
    },
]

export default function AboutPage() {
    const { currentUser, auth } = useAuth();
    return (
        <>
            <Navbar isUser={currentUser ? true : false} />
            <AboutSection />
            <StepsSection steps={steps} />
            <TestimonialSlider reviewComents={reviewsComments} reviewVideos={reviewVideos} />
            <FeatureSection features={features}/>
            <MoreSection qusAnsOne={qusAnsOne} qusAnsTow={qusAnsTow} targets={targets}/>
            <Footer />
        </>
    )
}
