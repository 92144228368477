import React, { useEffect, useState } from 'react'
import { TablesSettings } from '../../../../_metronic/partials/widgets/tables/TablesSettings'
import { useAuth } from '../../../modules/auth';
import { getRequest, postRequest, putRequest } from '../../../../helpers/axiosHelpers';
import Modal from "react-bootstrap/Modal";
import * as Yup from 'yup'
import clsx from 'clsx'
import { useFormik } from 'formik';
import { PageTitle } from '../../../../_metronic/layout/core';
import { useIntl } from 'react-intl';

type settingsModel = {
  id: number;
  key: string;
  type: string;
  value: string;
}

export default function Settings() {
  const intl = useIntl();
  const { auth } = useAuth();
  const [settings, setSettings] = useState<settingsModel[]>([]);
  const [editSetting, setEditSetting] = useState<settingsModel | null>(null)
  const [show, setShow] = useState<boolean>(false);
  const [loading, setLoading] = useState(false)

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const initialValues = {
    settings_value: editSetting ? editSetting.value : "",
  }

  const settingsSchema = Yup.object().shape({
    settings_value: Yup.string().required('Settings value is required'),
  })

  const formik = useFormik({
    initialValues,
    validationSchema: settingsSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      try {
        if(editSetting && auth){
          const response = await putRequest(`/settings/${editSetting.id}`, auth.api_token, {
            settings_key: editSetting.key,
            settings_value: values.settings_value,
            settings_type: editSetting.type,
          });
          console.log(response);
          setStatus('Settings updated');
          setSubmitting(false);
          setLoading(false);
          refreshSettings();
          handleClose();
        }
      } catch (error) {
        console.error(error);
        setStatus('Value is incorect');
        setSubmitting(false);
        setLoading(false);
      }
    },
  })

  async function refreshSettings() {
    const url = '/settings';
  
    if (auth) {
      try {
        const response = await getRequest(url, auth.api_token);
        let settings: settingsModel[] = [];
        response.data.data.forEach((setting: any) => {
          settings.push({
            id: setting.id,
            key: setting.settings_key,
            type: setting.settings_type,
            value: setting.settings_value,
          });
        });
        setSettings(settings);
      } catch (error) {
        console.error(error);
      }
    }
  }

  useEffect(() => {
    async function fetchSettings() {
      const url = '/settings';

      if (auth) {
        try {
          const response = await getRequest(url, auth.api_token);
          let settings: settingsModel[] = [];
          response.data.data.forEach((setting: any) => {
            settings.push({
              id: setting.id,
              key: setting.settings_key,
              type: setting.settings_type,
              value: setting.settings_value,
            });
          });
          setSettings(settings);
        } catch (error) {
          console.error(error);
        }
      }
    }
    fetchSettings();
  }, []);
  return (
    <>
      <PageTitle breadcrumbs={[]}>Settings</PageTitle>
      {settings.length &&
        <TablesSettings
          className={'settings-table'}
          settings={settings}
          editSetiings={(setting) => {
            setEditSetting(setting);
            handleShow();
          }} />
      }

      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Edit {editSetting?.key}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {editSetting?.type == 'string' &&
            <form
              className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
              noValidate
              id='kt_login_signup_form'
              onSubmit={formik.handleSubmit}
            >
              <div className='fv-row mb-8'>
                <label className='form-label fw-bolder text-dark fs-6'>Value</label>
                <input
                  placeholder='First name'
                  type='text'
                  autoComplete='off'
                  {...formik.getFieldProps('settings_value')}
                  className={clsx(
                    'form-control bg-transparent',
                    {
                      'is-invalid': formik.touched.settings_value && formik.errors.settings_value,
                    },
                    {
                      'is-valid': formik.touched.settings_value && !formik.errors.settings_value,
                    }
                  )}
                />
                {formik.touched.settings_value && formik.errors.settings_value && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.settings_value}</span>
                    </div>
                  </div>
                )}
              </div>
              <div className='text-center'>
                <button
                  type='submit'
                  id='kt_sign_up_submit'
                  className='btn btn-lg btn-primary w-100 mb-5'
                  disabled={formik.isSubmitting || !formik.isValid}
                >
                  {!loading && <span className='indicator-label'>Submit</span>}
                  {loading && (
                    <span className='indicator-progress' style={{ display: 'block' }}>
                      Please wait...{' '}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </div>
            </form>
          }
        </Modal.Body>
      </Modal>
    </>
  )
}
