import CourseDetails from '../../../../components/course-details/CourseDetails';
import Footer from '../../../../components/footer/Footer';
import Navbar from '../../../../components/navbar/Navbar';
import { useAuth } from '../../../../modules/auth';
import './../../../../../styles/reset.scss';
import './../../../../../styles/responsive.scss';


export default function CoursePage() {
    const { currentUser, auth } = useAuth();
    return (
        <>
            <Navbar isUser={currentUser ? true : false} />
            <CourseDetails/>
            <Footer/>
        </>
    )
};