import { Link } from 'react-router-dom'
import './featurecard.scss'

interface FeatureCardProps{
    icon: React.ReactElement,
    title: string,
    description: string,
    url: string,
}

export default function FeatureCard({icon, title, description, url}: FeatureCardProps) {
    return (
        <div className="col-12 col-md-6 col-lg-3">
            <div className="feature_card card">
                <div className="head">
                    <div className="icon_box">
                        {icon}
                    </div>
                    <h3>{title}</h3>
                </div>
                <div className="body">
                    <div className="body_text">
                        <div className="line"></div>
                        <p>{description}</p>
                    </div>
                    <Link to={url}>Learn More</Link>
                </div>
            </div>
        </div>
    )
}
