import { BsCalendarDate } from 'react-icons/bs'
import { IoMdStopwatch } from "react-icons/io";
import './eventcard.scss'

export default function EventCard() {
    return (
        <div className="card event_card">
            <div className="head">
                <div className="imgbox">
                    <img src="/blog1.jpg" alt="" />
                </div>
            </div>
            <div className="body">
                <h2 className="heading">Lorem ipsum dolor sit amet</h2>
                <div className="tag_box">
                    <div className="tag">Travel</div>
                    <span className="date">
                        <BsCalendarDate />
                        March 14,2023
                    </span>
                </div>
                <p className="pera">
                    Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                    Necessitatibus eum architecto atque nesciunt aut, animi
                    dignissimos, pariatur odit modi cupiditate, perspiciatis
                </p>

                <ul className="icon_with_text">
                    <li>
                    <IoMdStopwatch />
                        Showing
                    </li>
                    <li>
                    <IoMdStopwatch />
                        Showing
                    </li>
                    <li>
                    <IoMdStopwatch />
                        Showing
                    </li>
                </ul>
            </div>
        </div>
    )
}
