import './fillsection.scss'

export default function FillSection() {
    return (
        <section className="fill_section">
            <div className="container">
                <div className="row g-2 g-md-5">
                    <div className="col-12 col-md-6">
                        <div className="fill_text">
                            <h2>1. Fill in a brief</h2>
                            <p>
                                Tell us what you need help with,
                                describe your project requirements,
                                and set the deadline
                            </p>
                        </div>
                    </div>
                    <div className="col-12 col-md-6">
                        <div className="fill_img">
                            <div className="imgbox">
                                <img
                                    src="/fill1.jpg"
                                    alt="Picture of the author"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row g-2 g-md-5 mt-5">
                    <div className="col-12 col-md-6">
                        <div className="fill_img">
                            <div className="imgbox">
                                <img
                                    src="/fill2.jpg"
                                    alt="Picture of the author"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-6">
                        <div className="fill_text">
                            <h2>2. Choose an expert</h2>
                            <p>
                                Receive offers from those who can
                                help with assignments, compare their
                                ratings, and pick the best one for your task
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
