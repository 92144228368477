import React, {useEffect, useState} from 'react'
import {TablesCourseList} from '../../../../_metronic/partials/widgets/tables/TablesCourseList'
import {PageTitle} from '../../../../_metronic/layout/core'
import {useAuth} from '../../../modules/auth'
import {getRequest} from '../../../../helpers/axiosHelpers'
import Pagination from '../../../components/pagination/Pagination'

type courseModel = {
  courseId: number
  courseTitle: string
  category: string
  mentorId: number
  mentro: string
  mentorMail: string
  status: string
  startDate: string
  endDate: string
  uploadDate: string
}

export default function CourseList() {
  const {currentUser, auth} = useAuth()
  const [courses, setCourses] = useState<courseModel[]>([])
  const [selectedPage, setSelectedPage] = useState<number>(1)
  const [rowsPerPage, setRowsPerPage] = useState<number>(10)
  const [lastPage, setLastPage] = useState<number>(5)

  useEffect(() => {
    async function fetchCourse() {
      const url = `/courses?page=${selectedPage}&limit=${rowsPerPage}`
      const data = JSON.stringify({
        search: '',
      })

      if (auth) {
        try {
          const response = await getRequest(url, auth.api_token, data)
          let courses: courseModel[] = []
          response.data.data.forEach((course: any) => {
            courses.push({
              courseId: course.id,
              courseTitle: course.name,
              category: course.category.name,
              mentorId: course.created_by.id,
              mentro: course.created_by.name,
              mentorMail: course.created_by.email,
              status: course.status,
              startDate: course.start_date,
              endDate: course.end_date,
              uploadDate: new Date(course.created_at).toLocaleDateString(),
            })
          })
          setLastPage(response.data.meta.total / rowsPerPage)
          setCourses(courses)
        } catch (error) {
          console.error(error)
        }
      }
    }
    fetchCourse()
  }, [selectedPage, rowsPerPage])
  return (
    <>
      <PageTitle breadcrumbs={[]}>Course List</PageTitle>
      {courses.length && <TablesCourseList className={''} courses={courses} />}
      <Pagination
        selectedPage={selectedPage}
        lastPage={lastPage}
        setPage={(page) => setSelectedPage(page)}
      />
    </>
  )
}
