import axios from "axios";

const baseUrl = 'https://bf-backend.analysisace.com/api';

export async function putRequest(url: string, token: string, data: any) {
    let config = {
        method: 'put',
        maxBodyLength: Infinity,
        url: baseUrl + url,
        headers: {
            'Accept-Language': 'en',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer Bearer ' + token
        },
        data: data
    };

    return axios.request(config)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            throw error;
        });


}

export async function postRequest(url: string, token: string, data: any) {
    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: baseUrl + url,
        headers: {
            'Accept-Language': 'en',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer Bearer ' + token
        },
        data: data
    };

    return axios.request(config)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            throw error;
        });


}

export async function getRequest(url: string, token: string, data?: any) {
    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: baseUrl + url,
        headers: {
            'Accept-Language': 'es',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer Bearer ' + token
        },
        data: data
    };

    return axios.request(config)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            throw error;
        });
        
}