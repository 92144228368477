import FeatureCard from '../feature-card/FeatureCard';
import './featuresection.scss'

type feature = {
    icon: React.ReactElement,
    title: string,
    description: string,
    url: string,
}

interface FeatureSectionProps{
    features: feature[];
}

export default function FeatureSection({features}: FeatureSectionProps) {
  return (
    <section className="features_section">
            <div className="container">
                <h2>Features</h2>
                <p>
                    Lorem Ipsum is simply dummy text of the printing and 
                </p>
                <div className="row">
                    {features && features.map((feature, index)=>(
                        <FeatureCard 
                            key={index}
                            icon={feature.icon} 
                            title={feature.title} 
                            description={feature.description} 
                            url={feature.url}
                        />
                    ))}
                </div>
            </div>
        </section>
  )
}
