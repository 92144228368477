import React from 'react'
import './contactbox.scss'

interface ContactBoxProps{
    icon: React.ReactNode;
    title: string;
    contact: string;
}

export default function ContactBox({icon, title, contact} : ContactBoxProps) {
    return (
        <div className="col-12 col-md-4">
            <div className="contact_box">
                <div className="icon_box">{icon}</div>
                <div className="text_box">
                    <h2>{title}</h2>
                    <p>{contact}</p>
                </div>
            </div>
        </div>
    )
}
