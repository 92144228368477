import './../../../../styles/cart.scss'
import { useAuth } from '../../../modules/auth';
import Navbar from '../../../components/navbar/Navbar';
import Footer from '../../../components/footer/Footer';

export default function CartPage() {
    const { currentUser, auth } = useAuth();
    return (
        <>
            <Navbar isUser={currentUser ? true : false} />

            <section className='cart_section'>
                <div className='container'>
                    <ul className="cart_items">
                        <li className="items">
                            <div className="row">
                                <div className="col-12 col-md-4">
                                    <div className="img_content">
                                        <div className="imgbox">
                                            <img src="/blog1.jpg" alt=""/>
                                        </div>

                                        <div className="price_box">
                                            <h2>Final Price</h2>
                                            <h2>$99/year</h2>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-8">
                                    <div className="text_content">
                                        <h3 className="subheading">Order Details: Course</h3>
                                        <h2 className="heading">Order Details: Course</h2>
                                        <p className="description">
                                            How to create customize OPC UA server and
                                            connect it via OPC UA client
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </li>

                        <li className="items">
                            <div className="row">
                                <div className="col-12 col-md-4">
                                    <div className="img_content">
                                        <div className="imgbox">
                                            <img src="/blog1.jpg" alt=""/>
                                        </div>

                                        <div className="price_box">
                                            <h2>Final Price</h2>
                                            <h2>$99/year</h2>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-8">
                                    <div className="text_content">
                                        <h3 className="subheading">Order Details: Course</h3>
                                        <h2 className="heading">Order Details: Course</h2>
                                        <p className="description">
                                            How to create customize OPC UA server and
                                            connect it via OPC UA client
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </li>

                        <li className="items">
                            <div className="row">
                                <div className="col-12 col-md-4">
                                    <div className="img_content">
                                        <div className="imgbox">
                                            <img src="/blog1.jpg" alt=""/>
                                        </div>

                                        <div className="price_box">
                                            <h2>Final Price</h2>
                                            <h2>$99/year</h2>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-8">
                                    <div className="text_content">
                                        <h3 className="subheading">Order Details: Course</h3>
                                        <h2 className="heading">Order Details: Course</h2>
                                        <p className="description">
                                            How to create customize OPC UA server and
                                            connect it via OPC UA client
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>

                    <div className="sign_up_form">
                        <h2 className="title">Sign up</h2>
                        <form action="/">
                            <div className="input_box">
                                <label ><span>*</span>What's your name?</label>
                                <input type="text" placeholder="Name"/>
                            </div>
                            <div className="input_box">
                                <label ><span>*</span>What's your e-mail?</label>
                                <input type="email" placeholder="E-mail"/>
                            </div>
                            <div className="input_box">
                                <label ><span>*</span>Your password?</label>
                                <div className="password_box">
                                    <input type="password" placeholder="Password"/>
                                        <i className='bx bx-lock-alt'></i>
                                </div>
                            </div>
                            <div className="input_box">
                                <label ><span>*</span>Enter it once more, please</label>
                                <div className="password_box">
                                    <input type="password" placeholder="Password"/>
                                        <i className='bx bx-lock-open-alt' ></i>
                                </div>
                            </div>
                            <div className="input_box">
                                <label ><span>*</span>Country</label>
                                <select name="" id="">
                                    <option value="">---</option>
                                    <option value="bangladesh">Bangladesh</option>
                                </select>
                            </div>

                            <p className="have_account">
                                Do you already have an account? Log in
                                <a href="#">here</a>
                            </p>
                        </form>
                    </div>

                    <div className="billing_form">
                        <h2 className="title">Billing details</h2>
                        <form action="">
                            <div className="input_box">
                                <label ><span>*</span>Name</label>
                                <input type="text"/>
                            </div>
                            <div className="input_box">
                                <label ><span>*</span>Address</label>
                                <input type="text"/>
                            </div>
                            <div className="input_box">
                                <label ><span>*</span>City</label>
                                <input type="text"/>
                            </div>
                            <div className="input_box">
                                <label ><span>*</span>Postal code</label>
                                <input type="text"/>
                            </div>
                            <div className="input_box">
                                <label ><span>*</span>Country</label>
                                <select name="" id="">
                                    <option value="">---</option>
                                    <option value="bangladesh">Bangladesh</option>
                                </select>
                            </div>

                            <p className="business">
                                <input type="checkbox"/>
                                    I'm purchasing as a business
                            </p>
                        </form>
                    </div>

                    <div className="payment_form">
                        <h2 className="title">Payment Method</h2>
                        <form action="">
                            <ul className="method_list">
                                <li className="method active">
                                    <div className="imgbox">
                                        <img src="/logo-paypal.png" alt=""/>
                                    </div>
                                </li>
                                <li className="method">
                                    <div className="imgbox">
                                        <img src="/logo-paypal.png" alt=""/>
                                    </div>
                                </li>
                                <li className="method">
                                    <div className="imgbox">
                                        <img src="/logo-paypal.png" alt=""/>
                                    </div>
                                </li>
                                <li className="method">
                                    <div className="imgbox">
                                        <img src="/logo-paypal.png" alt=""/>
                                    </div>
                                </li>
                            </ul>

                            <div className="input_box full_width">
                                <label >Card Number</label>
                                <input type="text" placeholder="Name"/>
                            </div>
                            <div className="input_box">
                                <label >Expiration Date</label>
                                <input type="text" placeholder="Name"/>
                            </div>
                            <div className="input_box">
                                <label >CVC</label>
                                <input type="text" placeholder="Name"/>
                            </div>
                            <div className="input_box">
                                <label >Have a coupon?</label>
                                <input type="text" placeholder="Name"/>
                            </div>

                            <div className="input_box">
                                <button className="redeem">Redeem</button>
                            </div>

                            <div className="agreement_box">
                                <p className="agreement_one">
                                    <input type="checkbox"/>
                                        <span>*</span> I agree with <a href="#">Terms & Conditions</a>
                                </p>

                                <p className="agreement_tow">
                                    <input type="checkbox"/>
                                        I would like to receive news, tips and tricks, and other promotional material
                                </p>
                            </div>

                        </form>
                    </div>

                    <div className="submit_box">
                        <h2 className="total_price">Total: 39$</h2>
                        <button className="buy">Buy 39$</button>
                    </div>
                </div>
            </section>

            <Footer />
        </>
    )
}
