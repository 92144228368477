import React, { useEffect, useState } from 'react'
import './../../../../styles/reset.scss';
import './../../../../styles/responsive.scss';
import Navbar from '../../../components/navbar/Navbar';
import BannerSection from '../../../components/banner-section/BannerSection';
import AboutSection from '../../../components/about-section/AboutSection';
import FillSection from '../../../components/fill-section/FillSection';
import CourseSlider from '../../../components/course-slider/CourseSlider';
import TestimonialSlider from '../../../components/testimonial-slider/TestimonialSlider';
import FaqSection from '../../../components/faq-section/FaqSection';
import MeetSection from '../../../components/meet-section/MeetSection';
import Footer from '../../../components/footer/Footer';
import { courseModel, useAuth } from '../../../modules/auth';
import { getRequest } from '../../../../helpers/axiosHelpers';

// const freeCourses = [
//   {
//       courseLink: '/courses/free/1',
//       courseImgUrl: '/course1.jpg',
//       lessons: '3 Lessons',
//       type: 'Free',
//       title: 'Pilar Guerrero',
//       description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum',
//       mentorImgUrl: '/profile1.jpg',
//       mentorName: 'by Lorem Imsum',
//   },
//   {
//       courseLink: '/courses/free/2',
//       courseImgUrl: '/course2.jpg',
//       lessons: '3 Lessons',
//       type: 'Free',
//       title: 'Pilar Guerrero',
//       description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum',
//       mentorImgUrl: '/profile1.jpg',
//       mentorName: 'by Lorem Imsum',
//   },
//   {
//       courseLink: '/courses/free/3',
//       courseImgUrl: '/course1.jpg',
//       lessons: '3 Lessons',
//       type: 'Free',
//       title: 'Pilar Guerrero',
//       description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum',
//       mentorImgUrl: '/profile1.jpg',
//       mentorName: 'by Lorem Imsum',
//   },
//   {
//       courseLink: '/courses/free/4',
//       courseImgUrl: '/course2.jpg',
//       lessons: '3 Lessons',
//       type: 'Free',
//       title: 'Pilar Guerrero',
//       description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum',
//       mentorImgUrl: '/profile1.jpg',
//       mentorName: 'by Lorem Imsum',
//   },
// ]

// const premiumCourses = [
//   {
//       courseLink: '/courses/premium/1',
//       courseImgUrl: '/course1.jpg',
//       lessons: '3 Lessons',
//       type: 'Premium',
//       title: 'Pilar Guerrero',
//       description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum',
//       mentorImgUrl: '/profile1.jpg',
//       mentorName: 'by Lorem Imsum',
//   },
//   {
//       courseLink: '/courses/premium/2',
//       courseImgUrl: '/course3.jpg',
//       lessons: '3 Lessons',
//       type: 'Premium',
//       title: 'Pilar Guerrero',
//       description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum',
//       mentorImgUrl: '/profile1.jpg',
//       mentorName: 'by Lorem Imsum',
//   },
//   {
//       courseLink: '/courses/premium/3',
//       courseImgUrl: '/course1.jpg',
//       lessons: '3 Lessons',
//       type: 'Premium',
//       title: 'Pilar Guerrero',
//       description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum',
//       mentorImgUrl: '/profile1.jpg',
//       mentorName: 'by Lorem Imsum',
//   },
//   {
//       courseLink: '/courses/premium/4',
//       courseImgUrl: '/course3.jpg',
//       lessons: '3 Lessons',
//       type: 'Premium',
//       title: 'Pilar Guerrero',
//       description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum',
//       mentorImgUrl: '/profile1.jpg',
//       mentorName: 'by Lorem Imsum',
//   },
// ]

const reviewsComments = [
  {
    profileImg: '/testimonial1.jpg',
    comment: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. printing and typesetting industry.printing and typesetting',
    userName: 'Lorem Ipsum',
    userTitle: 'Lorem Ipsum',
    ratings: 4
  },
  {
    profileImg: '/testimonial2.jpg',
    comment: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. printing and typesetting industry.printing and typesetting',
    userName: 'Lorem Ipsum',
    userTitle: 'Lorem Ipsum',
    ratings: 4
  },
  {
    profileImg: '/testimonial3.jpg',
    comment: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. printing and typesetting industry.printing and typesetting',
    userName: 'Lorem Ipsum',
    userTitle: 'Lorem Ipsum',
    ratings: 4
  },
]

const reviewVideos = [
  {
    thumbnail: "/testimonial4.jpg",
    videoUrl: "/testimonial.mp4",
    userName: "Name",
    country: "Country",
    videoTitle: "Video title goes here",
  },
  {
    thumbnail: "/testimonial5.jpg",
    videoUrl: "/testimonial.mp4",
    userName: "Name",
    country: "Country",
    videoTitle: "Video title goes here",
  },
  {
    thumbnail: "/testimonial6.jpg",
    videoUrl: "/testimonial.mp4",
    userName: "Name",
    country: "Country",
    videoTitle: "Video title goes here",
  },
]

const leftFaq = [
  {
    title: "What is your experience on marketing?",
    description: "This is the first item's accordion body. It is shown by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the .accordion-body, though the transition does limit overflow.",
  },
  {
    title: "Do you offer discounts for students?",
    description: "This is the first item's accordion body. It is shown by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the .accordion-body, though the transition does limit overflow.",
  },
  {
    title: "Do you offer discounts for students?",
    description: "This is the first item's accordion body. It is shown by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the .accordion-body, though the transition does limit overflow.",
  },
  {
    title: "Do you offer discounts for students?",
    description: "This is the first item's accordion body. It is shown by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the .accordion-body, though the transition does limit overflow.",
  },
]

const rightFaq = [
  {
    title: "Is the course good for marketing beginners?",
    description: "This is the first item's accordion body. It is shown by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the .accordion-body, though the transition does limit overflow.",
  },
  {
    title: "Do you offer discounts for big teams?",
    description: "This is the first item's accordion body. It is shown by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the .accordion-body, though the transition does limit overflow.",
  },
  {
    title: "Do you have a refund policy for the course?",
    description: "This is the first item's accordion body. It is shown by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the .accordion-body, though the transition does limit overflow.",
  },
  {
    title: "Do you have a free class trial for the course?",
    description: "This is the first item's accordion body. It is shown by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the .accordion-body, though the transition does limit overflow.",
  },
]


export default function Home() {
  const { currentUser, auth } = useAuth();
  const [freeCourses, setFreeCourses] = useState<courseModel[]>([])
  const [premiumCourses, setPremiumCourses] = useState<courseModel[]>([])

  useEffect(() => {
    async function fetchFreeCourse() {
      const url = '/courses?page=1&limit=5';
      const data = JSON.stringify({
        "search": ""
      });

      if (auth) {
        try {
          const response = await getRequest(url, auth.api_token, data);
          let courses: courseModel[] = [];
          response.data.data.forEach((course) => {
            courses.push({
              courseLink: '/courses/free/' + course.id,
              courseImgUrl: course.image_url ? course.image_url : '/course1.jpg',
              lessons: '3 Lessons',
              type: 'Free',
              title: course.name,
              description: course.description,
              mentorImgUrl: '/profile1.jpg',
              mentorName: course.created_by.name,
            });
          });
          setFreeCourses(courses);
        } catch (error) {
          console.error(error);
        }
      }
    }
    fetchFreeCourse();


    async function fetchPremiumCourse() {
      const url = '/courses?page=1&limit=5';
      const data = JSON.stringify({
        "search": ""
      });

      if (auth) {
        try {
          const response = await getRequest(url, auth.api_token, data);
          let courses: courseModel[] = [];
          response.data.data.forEach((course) => {
            courses.push({
              courseLink: '/courses/premium/' + course.id,
              courseImgUrl: course.image_url ? course.image_url : '/course1.jpg',
              lessons: '3 Lessons',
              type: 'Premium',
              title: course.name,
              description: course.description,
              mentorImgUrl: '/profile1.jpg',
              mentorName: course.created_by.name,
            });
          });
          setPremiumCourses(courses);
        } catch (error) {
          console.error(error);
        }
      }
    }
    fetchPremiumCourse();


  }, [])

  return (
    <>
      <Navbar isUser={currentUser ? true : false} />
      <BannerSection />
      <AboutSection />
      <FillSection />
      
      {freeCourses && 
        <CourseSlider 
          title={'Free Courses'} 
          courses={freeCourses} 
          link={'/courses/free'} 
        />
      }

      <TestimonialSlider reviewComents={reviewsComments} reviewVideos={reviewVideos} />

      {premiumCourses && 
        <CourseSlider 
          title={'Premium Courses'} 
          courses={premiumCourses} 
          link={'/courses/premium'} 
        />
      }

      <FaqSection leftFaq={leftFaq} rightFaq={rightFaq} />
      <MeetSection />
      <Footer />
    </>
  )
}

