import './../../../../styles/reset.scss';
import './../../../../styles/responsive.scss';
import './../../../../styles/subscription.scss';
import Footer from '../../../components/footer/Footer';
import Navbar from '../../../components/navbar/Navbar';
import { useAuth } from '../../../modules/auth';
import { FaRegCheckCircle } from 'react-icons/fa';

export default function SubscriptionPage() {
    const { currentUser, auth } = useAuth();
    return (
        <>
            <Navbar isUser={currentUser ? true : false} />
            <section className='subscription_section'>
                <div className='container'>
                    <h2 className="heading">Subscription Plans</h2>
                    <p className="pera">Get access to 13 courses and save upto 183$</p>
                    <div className='row'>
                        <div className="col-12 col-md-4">
                            <div className="card subscription_card">
                                <div className="head">
                                    <div className="imgbox">
                                        <img src="/blog2.jpg" alt="" />
                                    </div>
                                </div>
                                <div className="body">
                                    <h2>Free sign up</h2>
                                    <h3><span>99$/</span>yearly</h3>
                                    <p>
                                        Lorem, ipsum dolor sit amet consectetur
                                        adipisicing elit. Dolorum quasi suscipit
                                    </p>
                                    <ul className="menu">
                                        <li>
                                            <span>
                                                <FaRegCheckCircle />
                                                Access to free course
                                            </span>
                                            <ul className="submenu">
                                                <li>Lorem ipsum dolor sit amet</li>
                                                <li>Lorem ipsum dolor sit amet</li>
                                                <li>Lorem ipsum dolor sit amet</li>
                                                <li>Lorem ipsum dolor sit amet</li>
                                            </ul>
                                        </li>
                                        <li>
                                            <span>
                                                <FaRegCheckCircle />
                                                Access to free course
                                            </span>
                                            <ul className="submenu">
                                                <li>Lorem ipsum dolor sit amet</li>
                                                <li>Lorem ipsum dolor sit amet</li>
                                                <li>Lorem ipsum dolor sit amet</li>
                                                <li>Lorem ipsum dolor sit amet</li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>

                                <div className="card_footer">
                                    <button>Subscribe now</button>
                                </div>

                            </div>
                        </div>
                        <div className="col-12 col-md-4">
                            <div className="card subscription_card center">
                                <div className="head">
                                    <div className="imgbox">
                                        <img src="/blog2.jpg" alt="" />
                                    </div>
                                </div>
                                <div className="body">
                                    <h2>Free sign up</h2>
                                    <h3><span>99$/</span>yearly</h3>
                                    <p>
                                        Lorem, ipsum dolor sit amet consectetur
                                        adipisicing elit. Dolorum quasi suscipit
                                    </p>
                                    <ul className="menu">
                                        <li>
                                            <span>
                                                <FaRegCheckCircle />
                                                Access to free course
                                            </span>
                                            <ul className="submenu">
                                                <li>Lorem ipsum dolor sit amet</li>
                                                <li>Lorem ipsum dolor sit amet</li>
                                                <li>Lorem ipsum dolor sit amet</li>
                                                <li>Lorem ipsum dolor sit amet</li>
                                            </ul>
                                        </li>
                                        <li>
                                            <span>
                                                <FaRegCheckCircle />
                                                Access to free course
                                            </span>
                                            <ul className="submenu">
                                                <li>Lorem ipsum dolor sit amet</li>
                                                <li>Lorem ipsum dolor sit amet</li>
                                                <li>Lorem ipsum dolor sit amet</li>
                                                <li>Lorem ipsum dolor sit amet</li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>

                                <div className="card_footer">
                                    <button>Subscribe now</button>
                                </div>

                            </div>
                        </div>
                        <div className="col-12 col-md-4">
                            <div className="card subscription_card">
                                <div className="head">
                                    <div className="imgbox">
                                        <img src="/blog2.jpg" alt="" />
                                    </div>
                                </div>
                                <div className="body">
                                    <h2>Free sign up</h2>
                                    <h3><span>99$/</span>yearly</h3>
                                    <p>
                                        Lorem, ipsum dolor sit amet consectetur
                                        adipisicing elit. Dolorum quasi suscipit
                                    </p>
                                    <ul className="menu">
                                        <li>
                                            <span>
                                                <FaRegCheckCircle />
                                                Access to free course
                                            </span>
                                            <ul className="submenu">
                                                <li>Lorem ipsum dolor sit amet</li>
                                                <li>Lorem ipsum dolor sit amet</li>
                                                <li>Lorem ipsum dolor sit amet</li>
                                                <li>Lorem ipsum dolor sit amet</li>
                                            </ul>
                                        </li>
                                        <li>
                                            <span>
                                                <FaRegCheckCircle />
                                                Access to free course
                                            </span>
                                            <ul className="submenu">
                                                <li>Lorem ipsum dolor sit amet</li>
                                                <li>Lorem ipsum dolor sit amet</li>
                                                <li>Lorem ipsum dolor sit amet</li>
                                                <li>Lorem ipsum dolor sit amet</li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>

                                <div className="card_footer">
                                    <button>Subscribe now</button>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}
