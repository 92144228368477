import './courseswrapper.scss'

type category = {
    title: string;
}

interface CoursesWrapperProps{
    title: string;
    categories: category[];
    children: React.ReactElement;
}

export default function CoursesWrapper({title, categories, children}: CoursesWrapperProps) {
    return (
        <section className="courses_section">
            <div className="container">
                <div className="heading_box">
                    <h2>{title}</h2>
                    <ul className="categories">
                        {categories && categories.map((category, index)=>(
                            <li className={index == 1 ? 'active' : ''}>{category.title}</li>
                        ))}
                    </ul>
                </div>
                <div className="row gy-4">
                    {children}
                </div>
            </div>
        </section>
    )
}
