import { BiChevronLeft, BiSearch } from 'react-icons/bi'
import './blogmenu.scss'
import RecentPost from '../recent-post/RecentPost';
import BlogCategory from '../blog-category/BlogCategory';

type recentPost = {
    imgUrl: string,
    title: string,
}

type blogCategory = {
    title: string,
    totalBlogs: number,
}

interface BlogMenuProps{
    recentPosts: recentPost[];
    blogCategories: blogCategory[];
    handleMenu: ()=>void;
}

export default function BlogMenu({recentPosts, blogCategories, handleMenu}: BlogMenuProps) {
    return (
        <div className="right_menu">
            <button className="menu_controler" onClick={handleMenu}>
                <BiSearch className="bx-search" />
                <BiChevronLeft className="bx-chevron-right" />
            </button>
            <div className="right_menu_content blog_menu">
                <div className="search_box">
                    <input type="text" placeholder="Enter Keyword " />
                    <button>Search</button>
                </div>


                <div className="recent_posts">
                    <h2>Recent Posts</h2>
                    <ul>
                        {recentPosts && recentPosts.map((recentPost, index)=>(
                            <RecentPost 
                                key={index} 
                                imgUrl={recentPost.imgUrl} 
                                title={recentPost.title}
                            />
                        ))}
                    </ul>
                </div>

                <div className="categories">
                    <h2>Categories</h2>
                    <ul>
                        {blogCategories && blogCategories.map((blogCategory, index)=>(
                            <BlogCategory 
                                key={index} 
                                title={blogCategory.title} 
                                totalBlogs={blogCategory.totalBlogs}
                            />
                        ))}
                    </ul>
                </div>

                <div className="populer_tags">
                    <h2>Popular Tags</h2>
                    <ul>
                        <li>IELTS Essentials</li>
                        <li>Language Master</li>
                        <li>Writing Skills</li>
                        <li>Speaking Prep</li>
                        <li>Test Prep</li>
                        <li>Language Master</li>
                        <li>Reading Strategies</li>
                    </ul>
                </div>

            </div>
        </div>
    )
}
