import { FiArrowUpRight } from 'react-icons/fi'
import './blogcard.scss'
import { Link } from 'react-router-dom';
import PostTime from '../post-time/PostTime';

interface BlogCardProps{
    imgUrl: string;
    title: string;
    description: string;
    link: string;
}

export default function BlogCard({imgUrl, title, description, link}: BlogCardProps) {
    return (
        <div className="col-12 col-md-6">
            <div className="card blog_card">
                <div className="head">
                    <div className="imgbox">
                    <img
                        src={imgUrl}
                        alt="Picture of the author"
                    />
                    </div>
                </div>
                <div className="body">
                    <PostTime/>
                    <h2>{title}</h2>
                    <p>{description}</p>
                    <Link to={link}>Learn More<FiArrowUpRight/></Link>
                </div>
            </div>
        </div>
    )
}
