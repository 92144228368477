import './classselector.scss'

type bfClass = {
    value: string | number;
    title: String;
}

interface ClassSelectorProps{
    classes : bfClass[];
}

export default function ClassSelector({classes}: ClassSelectorProps) {
    return (
        <select name="" id="" className="class_selector">
            {classes && classes.map((bfClass, index)=>(
                <option key={index} value={bfClass.value}>{bfClass.title}</option>
            ))}
        </select>
    )
}
