import './blogcategory.scss'

interface BlogCategoryProps {
    title: string;
    totalBlogs: number;
}

export default function BlogCategory({title, totalBlogs}: BlogCategoryProps) {
    return (
        <li className='categories_item'>
            <input type="checkbox" />
            {title} ({totalBlogs})
        </li>
    )
}
