import Rating from '../rating/Rating'
import './relatedcourse.scss'
import { LuUser } from "react-icons/lu";

export default function RelatedCourses() {
    return (
        <div className="related_courses">
                <h2 className="title">Related Courses</h2>
                <ul className="course_list">
                    <li className="course">
                        <div className="imgbox">
                            <img src="/banner.jpg" alt=""/>
                        </div>
                        <div className="text_box">
                            <a href="#">
                                <h2 className="course_title">How to Make Beautiful Landscape photos?</h2>
                            </a>
                            <div className="ratingbox">
                                <Rating ratings={4}/>
                                <span>4.8(867)</span>
                            </div>
                            <h3 className="aurthor">
                                <LuUser />
                                <span>By</span>
                                Tami Bua
                            </h3>
                        </div>
                    </li>
                    <li className="course">
                        <div className="imgbox">
                            <img src="/banner.jpg" alt=""/>
                        </div>
                        <div className="text_box">
                            <a href="#">
                                <h2 className="course_title">How to Make Beautiful Landscape photos?</h2>
                            </a>
                            <div className="ratingbox">
                            <Rating ratings={4}/>
                                <span>4.8(867)</span>
                            </div>
                            <h3 className="aurthor">
                                <LuUser />
                                <span>By</span>
                                Tami Bua
                            </h3>
                        </div>
                    </li>
                    <li className="course">
                        <div className="imgbox">
                            <img src="/banner.jpg" alt=""/>
                        </div>
                        <div className="text_box">
                            <a href="#">
                                <h2 className="course_title">How to Make Beautiful Landscape photos?</h2>
                            </a>
                            <div className="ratingbox">
                            <Rating ratings={4}/>
                                <span>4.8(867)</span>
                            </div>
                            <h3 className="aurthor">
                                <LuUser />
                                <span>By</span>
                                Tami Bua
                            </h3>
                        </div>
                    </li>
                </ul>
            </div>
    )
}
