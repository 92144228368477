import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';
import CourseCard from '../course-card/CourseCard';
import './courseslider.scss'
import Button from '../button/Button';

type Course = {
    courseLink: string;
    courseImgUrl: string;
    lessons: string;
    type: string;
    title: string;
    description: string;
    mentorImgUrl: string;
    mentorName: string;
}

interface CourseSliderProps {
    title: string;
    courses: Course[];
    link: string;
}

export default function CourseSlider({ title, courses, link }: CourseSliderProps) {
    return (
        <section className="course_section">
            <div className="container">
                <div className="heading_box">
                    <h2>{title}</h2>
                    <Button title={'EXPLORE COURSES'} link={link} />
                </div>
                <div className="swiper_container">
                    <Swiper
                        modules={[Pagination]}
                        slidesPerView={1}
                        spaceBetween={30}
                        pagination={{
                            clickable: true,
                        }}
                        breakpoints={{
                            768: {
                                slidesPerView: 2,
                                spaceBetween: 50,
                            }
                        }}
                    >
                        {courses && courses.map((course, index) => (
                            <SwiperSlide key={index}>
                                <CourseCard 
                                    courseLink={course.courseLink}
                                    courseImgUrl={course.courseImgUrl} 
                                    lessons={course.lessons} 
                                    type={course.type} 
                                    title={course.title} 
                                    description={course.description} 
                                    mentorImgUrl={course.mentorImgUrl} 
                                    mentorName={course.mentorName} 
                                />
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
            </div>
        </section>
    )
}
