import Rating from '../rating/Rating';
import './reviewcomment.scss'

interface ReviewCommentProps {
    profileImg: string;
    comment: string;
    userName: string;
    userTitle: string;
    ratings: number;
}

export default function ReviewComment({profileImg, comment, userName, userTitle, ratings}: ReviewCommentProps) {


    return (
        <div className="card comment_review">
            <div className="head">
                <div className="imgbox">
                    <img
                        src={profileImg}
                        alt="Picture of the author"
                    />
                </div>
            </div>
            <div className="body">
                <p>
                    {comment}
                </p>
            </div>
            <div className="footer">
                <div className="heading_box">
                    <h4>{userName}</h4>
                    <h5>{userTitle}</h5>
                </div>
                <Rating ratings={ratings}/>
            </div>
        </div>
    )
}
