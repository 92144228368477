/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {KTIcon, toAbsoluteUrl} from '../../../helpers'
import {Link} from 'react-router-dom'

type courseModel = {
  courseId: number
  courseTitle: string
  category: string
  mentorId: number
  mentro: string
  mentorMail: string
  status: string
  startDate: string
  endDate: string
  uploadDate: string
}

type Props = {
  className: string
  courses: courseModel[]
}

const TablesCourseList: React.FC<Props> = ({className, courses}) => {
  function checkStatus(status) {
    switch (status) {
      case 'published':
        return 'badge-light-primary'
      default:
        return 'badge-light-danger'
    }
  }
  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>All courses</span>
          <span className='text-muted mt-1 fw-semibold fs-7'>Over 500 courses</span>
        </h3>

        <div className='card-toolbar'>
          {/* begin::Menu */}
          <button
            type='button'
            className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='top-end'
          >
            <KTIcon iconName='category' className='fs-2' />
          </button>
          {/* begin::Menu 2 */}
          <div
            className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold w-200px'
            data-kt-menu='true'
          >
            {/* begin::Menu item */}
            <div className='menu-item px-3'>
              <div className='menu-content fs-6 text-dark fw-bold px-3 py-4'>Quick Actions</div>
            </div>
            {/* end::Menu item */}
            {/* begin::Menu separator */}
            <div className='separator mb-3 opacity-75'></div>
            {/* end::Menu separator */}
            {/* begin::Menu item */}
            <div className='menu-item px-3'>
              <a href='#' className='menu-link px-3'>
                New Ticket
              </a>
            </div>
            {/* end::Menu item */}
            {/* begin::Menu item */}
            <div className='menu-item px-3'>
              <a href='#' className='menu-link px-3'>
                New Customer
              </a>
            </div>
            {/* end::Menu item */}
            {/* begin::Menu item */}
            <div
              className='menu-item px-3'
              data-kt-menu-trigger='hover'
              data-kt-menu-placement='right-start'
              data-kt-menu-flip='left-start, top'
            >
              {/* begin::Menu item */}
              <a href='#' className='menu-link px-3'>
                <span className='menu-title'>New Group</span>
                <span className='menu-arrow'></span>
              </a>
              {/* end::Menu item */}
              {/* begin::Menu sub */}
              <div className='menu-sub menu-sub-dropdown w-175px py-4'>
                {/* begin::Menu item */}
                <div className='menu-item px-3'>
                  <a href='#' className='menu-link px-3'>
                    Admin Group
                  </a>
                </div>
                {/* end::Menu item */}
                {/* begin::Menu item */}
                <div className='menu-item px-3'>
                  <a href='#' className='menu-link px-3'>
                    Staff Group
                  </a>
                </div>
                {/* end::Menu item */}
                {/* begin::Menu item */}
                <div className='menu-item px-3'>
                  <a href='#' className='menu-link px-3'>
                    Member Group
                  </a>
                </div>
                {/* end::Menu item */}
              </div>
              {/* end::Menu sub */}
            </div>
            {/* end::Menu item */}
            {/* begin::Menu item */}
            <div className='menu-item px-3'>
              <a href='#' className='menu-link px-3'>
                New Contact
              </a>
            </div>
            {/* end::Menu item */}
            {/* begin::Menu separator */}
            <div className='separator mt-3 opacity-75'></div>
            {/* end::Menu separator */}
            {/* begin::Menu item */}
            <div className='menu-item px-3'>
              <div className='menu-content px-3 py-3'>
                <a className='btn btn-primary btn-sm px-4' href='#'>
                  Generate Reports
                </a>
              </div>
            </div>
            {/* end::Menu item */}
          </div>
          {/* end::Menu 2 */}
          {/* end::Menu */}
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold text-muted bg-light'>
                <th className='ps-4 min-w-150px rounded-start'>Course title</th>
                <th className='min-w-300px'>Mentor</th>
                <th className='min-w-100px'>Status</th>
                <th className='min-w-100px'>Start date</th>
                <th className='min-w-100px'>End date</th>
                <th className='min-w-100px'>Upload date</th>
                <th className='min-w-200px text-end rounded-end'></th>
              </tr>
            </thead>
            {/* end::Table head */}

            {/* begin::Table body */}
            <tbody>
              {courses &&
                courses.map((course, index) => (
                  <tr key={index}>
                    <td>
                      <Link
                        to={`/admin/course/${course.courseId}`}
                        className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                      >
                        {course.courseTitle}
                      </Link>
                      <span className='text-muted fw-semibold text-muted d-block fs-7'>
                        {course.category}
                      </span>
                    </td>

                    <td>
                      <div className='d-flex align-items-center'>
                        <div className='symbol symbol-50px me-5'>
                          <span className='symbol-label bg-light'>
                            <img
                              src={toAbsoluteUrl('/media/svg/avatars/001-boy.svg')}
                              className='h-75 align-self-end'
                              alt=''
                            />
                          </span>
                        </div>
                        <div className='d-flex justify-content-start flex-column'>
                          <Link
                            to={`/admin/mentor/${course.mentorId}`}
                            className='text-dark fw-bold text-hover-primary mb-1 fs-6'
                          >
                            {course.mentro}
                          </Link>
                          <span className='text-muted fw-semibold text-muted d-block fs-7'>
                            {course.mentorMail}
                          </span>
                        </div>
                      </div>
                    </td>

                    <td>
                      <span className={`badge ${checkStatus(course.status)} fs-7 fw-semibold`}>
                        {course.status}
                      </span>
                    </td>

                    <td>
                      <span className='text-muted fw-semibold text-muted d-block fs-7'>
                        {course.startDate}
                      </span>
                    </td>

                    <td>
                      <span className='text-muted fw-semibold text-muted d-block fs-7'>
                        {course.endDate}
                      </span>
                    </td>

                    <td>
                      <span className='text-muted fw-semibold text-muted d-block fs-7'>
                        {course.uploadDate}
                      </span>
                    </td>

                    <td className='text-end'>
                      <a
                        href='#'
                        className='btn btn-bg-light btn-color-muted btn-active-color-primary btn-sm px-4 me-2'
                      >
                        View
                      </a>
                      <a
                        href='#'
                        className='btn btn-bg-light btn-color-muted btn-active-color-primary btn-sm px-4'
                      >
                        Edit
                      </a>
                      <a
                        href='#'
                        className='btn btn-bg-light btn-color-muted btn-active-color-danger btn-sm px-4'
                      >
                        Delete
                      </a>
                    </td>
                  </tr>
                ))}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
  )
}

export {TablesCourseList}
