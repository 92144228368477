import './qusans.scss'

interface QusAnsProps{
    icon: React.ReactElement;
    title: string;
    description: string;
}

export default function QusAns({icon, title, description}: QusAnsProps) {
    return (
        <li className='more_list_item'>
            <div className="icon_box">{icon}</div>
            <div className="text_box">
                <h3>{title}</h3>
                <p>{description}</p>
            </div>
        </li>
    )
}
