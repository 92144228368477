import { useEffect, useState } from 'react';
import BlogSection from '../../../components/blog-section/BlogSection';
import BlogWrapper from '../../../components/blog-wrapper/BlogWrapper';
import Footer from '../../../components/footer/Footer';
import Navbar from '../../../components/navbar/Navbar';
import { useAuth } from '../../../modules/auth';
import './../../../../styles/reset.scss';
import './../../../../styles/responsive.scss';
import { getRequest } from '../../../../helpers/axiosHelpers';

const recentPosts = [
  {
    imgUrl: "/post1.jpg",
    title: "IELTS exam preparation at Manhattan language",
  },
  {
    imgUrl: "/post2.jpg",
    title: "Learning, friendship and fun for everyone",
  },
  {
    imgUrl: "/post3.jpg",
    title: "Experiences Through Education",
  },
  {
    imgUrl: "/post4.jpg",
    title: "10 Ways to learn english in NYC",
  },
]

const blogCategories = [
  {
    title: "IELTS Essentials",
    totalBlogs: 8,
  },
  {
    title: "Language Master",
    totalBlogs: 12,
  },
  {
    title: "Test Prep",
    totalBlogs: 0,
  },
  {
    title: "Writing Skills",
    totalBlogs: 6,
  },
  {
    title: "Speaking Prep",
    totalBlogs: 10,
  },
  {
    title: "Listening Practice",
    totalBlogs: 0,
  },
  {
    title: "Reading Strategies",
    totalBlogs: 4,
  },

]

const blogs = [
  {
    imgUrl: "/blog1.jpg",
    title: "IELTS exam preparation at Manhattan language",
    description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text",
    link: "/blogs/1",
  },
  {
    imgUrl: "/blog2.jpg",
    title: "IELTS exam preparation at Manhattan language",
    description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text",
    link: "/blogs/2",
  },
  {
    imgUrl: "/blog3.jpg",
    title: "Studying to accomplish the greatest goals",
    description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text",
    link: "/blogs/3",
  },
  {
    imgUrl: "/blog4.jpg",
    title: "Studying to accomplish the greatest goals",
    description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text",
    link: "/blogs/4",
  },
  {
    imgUrl: "/blog5.jpg",
    title: "Studying to accomplish the greatest goals",
    description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text",
    link: "/blogs/5",
  },
  {
    imgUrl: "/blog6.jpg",
    title: "Studying to accomplish the greatest goals",
    description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text",
    link: "/blogs/6",
  },
]

interface blogsModel {
  imgUrl: string,
  title: string,
  description: string,
  link: string,
}

export default function BlogsPage() {
  const { currentUser, auth } = useAuth();
  // const [blogs, setBlogs] = useState<blogsModel[]>([]);

  return (
    <>
      <Navbar isUser={currentUser ? true : false} />

      <BlogWrapper
        recentPosts={recentPosts}
        blogCategories={blogCategories}
      >
        <BlogSection blogs={blogs} />
      </BlogWrapper>

      <Footer />
    </>
  )
}
