import { AiOutlinePlus } from 'react-icons/ai';
import BfAccordion from '../bf-accordion/BfAccordion'
import './faqsection.scss'

type item = {
    title: string;
    description: string;
  }
  
interface BfAccordionProps {
    leftFaq : item[];
    rightFaq : item[];
}

export default function FaqSection({leftFaq, rightFaq}: BfAccordionProps) {
    return (
        <section className="faq_section">
            <div className="container">
                <h3>FAQ</h3>
                <h4>Frequently Asked Question</h4>
                <p>
                    Lorem Ipsum is simply dummy text of the printing <br />
                        and typesetting industry.
                </p>
                <div className="row">
                    <div className="col-12 col-md-6">
                        <BfAccordion items={leftFaq} accordionClass={'faq_accordion'} icon={<AiOutlinePlus />}/>
                    </div>


                    <div className="col-12 col-md-6">
                        <BfAccordion items={rightFaq} accordionClass={'faq_accordion'} icon={<AiOutlinePlus />}/>
                    </div>

                </div>
            </div>
        </section>
    )
}
