import { useParams } from 'react-router-dom';
import CourseCard from '../../../components/course-card/CourseCard';
import CoursesWrapper from '../../../components/courses-wrapper/CoursesWrapper';
import Footer from '../../../components/footer/Footer';
import Navbar from '../../../components/navbar/Navbar';
import { courseModel, useAuth } from '../../../modules/auth';
import './../../../../styles/reset.scss';
import './../../../../styles/responsive.scss';
import { useEffect, useState } from 'react';
import { getRequest } from '../../../../helpers/axiosHelpers';

const categories = [
    {
        title: 'All'
    },
    {
        title: 'Marketing'
    },
    {
        title: 'Software'
    },
    {
        title: 'Art & Design'
    },
]


export default function CoursesPage() {
    const { currentUser, auth } = useAuth();
    const { slug } = useParams();
    const [courses, setCourses] = useState<courseModel[]>([]);

    useEffect(() => {
        async function fetchCourse() {
            const url = '/courses?page=1&limit=10';
            const data = JSON.stringify({
                "search": ""
            });

            if (auth) {
                try {
                    const response = await getRequest(url, auth.api_token, data);
                    let courses: courseModel[] = [];
                    response.data.data.forEach((course : any) => {
                        courses.push({
                            courseLink: '/courses/' + slug + '/' + course.id,
                            courseImgUrl: course.image_url ? course.image_url : '/course1.jpg',
                            lessons: '3 Lessons',
                            type: slug ? slug == 'free' ? 'Free Courses' : 'Premium Course' : 'null',
                            title: course.name,
                            description: course.description,
                            mentorImgUrl: '/profile1.jpg',
                            mentorName: course.created_by.name,
                        });
                    });
                    setCourses(courses);
                } catch (error) {
                    console.error(error);
                }
            }
        }
        fetchCourse();
    }, []);
    return (
        <>
            <Navbar isUser={currentUser ? true : false} />
            <CoursesWrapper
                title={slug == 'free' ? 'Free Courses' : 'Premium Course'}
                categories={categories}
            >
                <>
                    {courses && courses.map((course, index) => (
                        <div key={index} className="col-12 col-md-6">
                            <CourseCard
                                courseLink={course.courseLink}
                                courseImgUrl={course.courseImgUrl}
                                lessons={course.lessons}
                                type={course.type}
                                title={course.title}
                                description={course.description}
                                mentorImgUrl={course.mentorImgUrl}
                                mentorName={course.mentorName}
                            />
                        </div>
                    ))}
                </>
            </CoursesWrapper>
            <Footer />
        </>
    )
};
