import { Link } from 'react-router-dom';
import './button.scss'

interface ButtonProps {
    title: string;
    link: string;
}

export default function Button({title, link}: ButtonProps) {
  return (
    <Link className='c_button' to={link}>{title}</Link>
  )
}
