import React, { useState } from 'react'
import _ from 'underscore';
import { useAuth } from '../../modules/auth';
import { getRequest } from '../../../helpers/axiosHelpers';

type roleModel = {
    id: number;
    name: string;
  }
  type permissionModel = {
    id: number;
    name: string;
    roles: any[];
  }

interface checkboxProps {
    permission: permissionModel;
    role: roleModel;

}

export default function CheckBox({permission, role}:checkboxProps) {
    const { auth } = useAuth();
    const [isChecked, setisChecked] = useState<boolean>(_.contains(_.pluck(permission.roles, 'name'), role.name))

    async function changePermission(url: string) {
        if (auth) {
            try {
                const response = await getRequest(url, auth.api_token);
            } catch (error) {
                setisChecked(!isChecked);
            }
        }
    }

    function handlePermission(isChecked: boolean, permissionId: number, RoleId: number) {
        setisChecked(!isChecked);
        if (isChecked) {
            changePermission(`/revoke-permission/${permissionId}/role/${RoleId}`);
        } else {
            changePermission(`/add-permission/${permissionId}/role/${RoleId}`);
        }
    }

    return (
        <input
            className='form-check-input widget-13-check'
            type='checkbox'
            value='1'
            checked={isChecked}
            onChange={
                () => handlePermission(isChecked, permission.id, role.id)
            }
        />
    )
}
