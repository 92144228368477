"use client"
import Accordion from 'react-bootstrap/Accordion';
import './bfaccordion.scss'

type item = {
  title: string;
  description: string;
}

interface BfAccordionProps {
  items: item[];
  accordionClass: string;
  icon: React.ReactElement;
}

export default function BfAccordion({ items, accordionClass, icon }: BfAccordionProps) {
  return (
    <Accordion className={accordionClass} defaultActiveKey="0">
      {items && items.map((item, index) => (
        <Accordion.Item className="accordion-item" eventKey={index.toString()} key={index}>
          <Accordion.Header>
            <span>{icon}</span>
            {item.title}
          </Accordion.Header>
          <Accordion.Body>
            {item.description}
          </Accordion.Body>
        </Accordion.Item>
      ))}
    </Accordion>
  );
}

