import { AiOutlineClose } from 'react-icons/ai';
import './modal.scss'

interface ModalProps {
    status: boolean;
    closeModal: ()=>void;
    children: React.ReactNode;
}

export default function Modal({status, closeModal, children} : ModalProps) {
    return (
        <div id="modal-container" className={`active_modal ${!status && 'out'}`}>
            <div className="modal-background">
                <div className="modal">
                    {children}
                    <button className="modalClose" onClick={closeModal}>
                        <AiOutlineClose/>
                    </button>
                </div>
            </div>
        </div>
    )
}
