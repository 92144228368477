import ClassSelector from '../class-selector/ClassSelector'
import './leasondetails.scss'

type bfClass = {
    value: string | number;
    title: String;
}

type lesson = {
    imgUrl: string;
    title: string;
    description: string;
}

interface CourseDetailsProps {
    classes: bfClass[];
    lessons: lesson[];
}



export default function LeasonDetails({ classes, lessons }: CourseDetailsProps) {
    return (
        <section className='course_details_section'>
            <div className='container'>
                <div className='row'>
                    <div className="col-12 offset-0 col-md-6 offset-md-3">
                        <div className="course_heading">
                            <h2>Complete English</h2>
                            <ClassSelector classes={classes} />
                            <div className="className_completation_bar">
                                <div className="bar_status"></div>
                            </div>
                        </div>

                        <div className="course_type">
                            <div className="icon_box">
                                <svg xmlns="http://www.w3.org/2000/svg" width="72" height="72" viewBox="0 0 72 72" fill="none">
                                    <path d="M6.01562 57H66.0157V63H6.01562V57ZM6.01562 15L21.0156 25.5L36.0157 6L51.0157 25.5L66.0157 15V51H6.01562V15ZM12.0156 26.5239V45H60.0157V26.5239L49.7572 33.7047L36.0157 15.8407L22.274 33.7047L12.0156 26.5239Z" fill="#465475" />
                                </svg>
                            </div>
                            <div className="text_box">
                                <span className="tag">Lorem Ipsum</span>
                                <p>Try Premium for free</p>
                            </div>
                        </div>

                        <div className="course_chapters">
                            <h3>Chapter 1</h3>
                            <p>Lorem Ipsum</p>

                            <ul className="lessons">
                                {lessons && lessons.map((lesson, index) => (
                                    <li>
                                        <div className="icon_box">
                                            <div className="imgbox">
                                                <img
                                                    src={lesson.imgUrl}
                                                    alt="Picture of the author"
                                                />
                                            </div>
                                        </div>
                                        <div className="text_box">
                                            <h3>{lesson.title}</h3>
                                            <h4>{lesson.description}</h4>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
