import './stepssection.scss'
import BfStep from '../bf-step/BfStep'

type step = {
    icon: React.ReactElement,
    title: string,
    description: string,
}

interface StepsSectionProps {
    steps: step[];
}

export default function StepsSection({ steps }: StepsSectionProps) {
    return (
        <section className="steps_section">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-lg-6">
                        <div className="steps_text">
                            <h2>How i do get started</h2>
                            <div className="row gy-4">
                                {steps && steps.map((step, index) => (
                                    <BfStep 
                                        key={index} 
                                        icon={step.icon} 
                                        title={step.title} 
                                        description={step.description} 
                                    />
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-6 mt-5 mt-lg-0">
                        <div className="steps_img">
                            <div className="imgbox">
                                <img
                                    src="/steps.jpg"
                                    alt="Picture of the author"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
