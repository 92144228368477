import React from 'react'
interface PaginationProps {
  selectedPage: number
  lastPage: number
  setPage: (page: number) => void
}
export default function Pagination({selectedPage, lastPage, setPage}: PaginationProps) {
  return (
    <ul className='pagination mt-5'>
      {selectedPage && (
        <>
          <li
            className={`page-item previous ${selectedPage <= 1 && 'disabled'}`}
            onClick={() => selectedPage > 1 && setPage(selectedPage - 1)}
          >
            <a href='#' className='page-link'>
              <i className='previous'></i>
            </a>
          </li>
          {selectedPage - 2 > 1 && (
            <li className='page-item' onClick={() => setPage(selectedPage - 3)}>
              <a href='#' className='page-link'>
                {selectedPage - 3}
              </a>
            </li>
          )}
          {selectedPage - 1 > 1 && (
            <li className='page-item' onClick={() => setPage(selectedPage - 2)}>
              <a href='#' className='page-link'>
                {selectedPage - 2}
              </a>
            </li>
          )}
          {selectedPage > 1 && (
            <li className='page-item' onClick={() => setPage(selectedPage - 1)}>
              <a href='#' className='page-link'>
                {selectedPage - 1}
              </a>
            </li>
          )}
          <li className='page-item active'>
            <a href='#' className='page-link'>
              {selectedPage}
            </a>
          </li>
          {selectedPage < lastPage && (
            <li className='page-item' onClick={() => setPage(selectedPage + 1)}>
              <a href='#' className='page-link'>
                {selectedPage + 1}
              </a>
            </li>
          )}
          {selectedPage + 1 < lastPage && (
            <li className='page-item' onClick={() => setPage(selectedPage + 2)}>
              <a href='#' className='page-link'>
                {selectedPage + 2}
              </a>
            </li>
          )}
          {selectedPage + 2 < lastPage && (
            <li className='page-item' onClick={() => setPage(selectedPage + 3)}>
              <a href='#' className='page-link'>
                {selectedPage + 3}
              </a>
            </li>
          )}
          
          {selectedPage + 3 < lastPage && (
            <>
              {selectedPage + 4 < lastPage && (<>...</>)}
              <li className='page-item' onClick={() => setPage(lastPage)}>
                <a href='#' className='page-link'>
                  {lastPage}
                </a>
              </li>
            </>
          )}
          <li
            className={`page-item next ${selectedPage >= lastPage && 'disabled'}`}
            onClick={() => selectedPage < lastPage && setPage(selectedPage + 1)}
          >
            <a href='#' className='page-link'>
              <i className='next'></i>
            </a>
          </li>
        </>
      )}
    </ul>
  )
}
