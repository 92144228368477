import { BiSolidMessageRoundedDots, BiSolidUser } from 'react-icons/bi';
import { BsCalendarDate } from 'react-icons/bs';
import './post-time.scss'

export default function PostTime() {
    return (
        <ul className="post_time">
            <li>
                <BiSolidUser />
                by Admin
            </li>
            <li>
                <BsCalendarDate />
                23 Aug, 2023
            </li>
            <li>
                <BiSolidMessageRoundedDots />
                22 Comments
            </li>
        </ul>
    )
}
