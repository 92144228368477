import './../../../../styles/reset.scss';
import './../../../../styles/responsive.scss';
import './../../../../styles/event.scss';
import EventCard from '../../../components/event-card/EventCard';
import { BsCalendarDate } from 'react-icons/bs';
import Footer from '../../../components/footer/Footer';
import Navbar from '../../../components/navbar/Navbar';
import { useAuth } from '../../../modules/auth';
import { Accordion } from 'react-bootstrap';



export default function EventPage() {
    const { currentUser, auth } = useAuth();
    return (
        <>
            <Navbar isUser={currentUser ? true : false} />

            <section className='events_section'>
                <div className='container'>
                    <h2 className="heading">Upcoming <span>Events</span></h2>
                    <div className="devider_box">
                        <div className="devider">
                            <BsCalendarDate />
                        </div>
                    </div>
                    <p className="pera">
                        Lorem ipsum dolor sit amet consectetur
                        adipisicing elit. Unde repellat omnis
                    </p>

                    <div className='row g-4'>
                        <div className="col-12 col-md-6 col-lg-4">
                            <EventCard />
                        </div>
                        <div className="col-12 col-md-6 col-lg-4">
                            <EventCard />
                        </div>
                        <div className="col-12 col-md-6 col-lg-4">
                            <EventCard />
                        </div>
                        <div className="col-12 col-md-6 col-lg-4">
                            <EventCard />
                        </div>
                    </div>
                </div>
            </section>

            <section className='event_list_section'>
                <div className='container'>
                    <div className='row'>
                        <div className="col-12 col-md-2">
                            <div className="button_box">
                                <div className="button_wrapper" style={{ backgroundColor: "aqua" }}>
                                    <div className="button">
                                        <span className="title">First Day</span>
                                        <span className="date">28 Jul,2023</span>
                                    </div>
                                </div>
                                <div className="button_wrapper" style={{ backgroundColor: "chocolate" }}>
                                    <div className="button active">
                                        <span className="title">First Day</span>
                                        <span className="date">28 Jul,2023</span>
                                    </div>
                                </div>
                                <div className="button_wrapper" style={{ backgroundColor: "coral" }}>
                                    <div className="button">
                                        <span className="title">First Day</span>
                                        <span className="date">28 Jul,2023</span>
                                    </div>
                                </div>
                                <div className="button_wrapper" style={{ backgroundColor: "navajowhite" }}>
                                    <div className="button">
                                        <span className="title">First Day</span>
                                        <span className="date">28 Jul,2023</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-10">
                            <Accordion defaultActiveKey="0">
                                <Accordion.Item eventKey="0" className="event_accordion">
                                    <Accordion.Header className="head">
                                        <div className="imgbox">
                                            <img src="/profile1.jpg" alt="" />
                                        </div>
                                        <div className="text_box">
                                            <div className="time">
                                                <h3>09:00 <span>AM</span> : 10:30 <span>AM</span></h3>
                                            </div>
                                            <h2 className="title">Lorem ipsum dolor sit amet consectetur</h2>
                                            <h4 className="aurthor">By <span>Riaz Sagar</span> ,Logichunt Inc.</h4>
                                        </div>
                                    </Accordion.Header>
                                    <Accordion.Body className="body">
                                        <p className="pera">
                                            Lorem, ipsum dolor sit amet consectetur adipisicing elit. Repudiandae, adipisci.
                                            Lorem, ipsum dolor sit amet consectetur adipisicing elit. Repudiandae, adipisci.
                                        </p>
                                        <h4 className="location">
                                            <span>Location:</span> Hall 1, Building A, Golden Street, <span>Southafrica</span>
                                        </h4>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="1" className="event_accordion">
                                    <Accordion.Header className="head">
                                        <div className="imgbox">
                                            <img src="/profile1.jpg" alt="" />
                                        </div>
                                        <div className="text_box">
                                            <div className="time">
                                                <h3>09:00 <span>AM</span> : 10:30 <span>AM</span></h3>
                                            </div>
                                            <h2 className="title">Lorem ipsum dolor sit amet consectetur</h2>
                                            <h4 className="aurthor">By <span>Riaz Sagar</span> ,Logichunt Inc.</h4>
                                        </div>
                                    </Accordion.Header>
                                    <Accordion.Body className="body">
                                        <p className="pera">
                                            Lorem, ipsum dolor sit amet consectetur adipisicing elit. Repudiandae, adipisci.
                                            Lorem, ipsum dolor sit amet consectetur adipisicing elit. Repudiandae, adipisci.
                                        </p>
                                        <h4 className="location">
                                            <span>Location:</span> Hall 1, Building A, Golden Street, <span>Southafrica</span>
                                        </h4>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="2" className="event_accordion">
                                    <Accordion.Header className="head">
                                        <div className="imgbox">
                                            <img src="/profile1.jpg" alt="" />
                                        </div>
                                        <div className="text_box">
                                            <div className="time">
                                                <h3>09:00 <span>AM</span> : 10:30 <span>AM</span></h3>
                                            </div>
                                            <h2 className="title">Lorem ipsum dolor sit amet consectetur</h2>
                                            <h4 className="aurthor">By <span>Riaz Sagar</span> ,Logichunt Inc.</h4>
                                        </div>
                                    </Accordion.Header>
                                    <Accordion.Body className="body">
                                        <p className="pera">
                                            Lorem, ipsum dolor sit amet consectetur adipisicing elit. Repudiandae, adipisci.
                                            Lorem, ipsum dolor sit amet consectetur adipisicing elit. Repudiandae, adipisci.
                                        </p>
                                        <h4 className="location">
                                            <span>Location:</span> Hall 1, Building A, Golden Street, <span>Southafrica</span>
                                        </h4>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="3" className="event_accordion">
                                    <Accordion.Header className="head">
                                        <div className="imgbox">
                                            <img src="/profile1.jpg" alt="" />
                                        </div>
                                        <div className="text_box">
                                            <div className="time">
                                                <h3>09:00 <span>AM</span> : 10:30 <span>AM</span></h3>
                                            </div>
                                            <h2 className="title">Lorem ipsum dolor sit amet consectetur</h2>
                                            <h4 className="aurthor">By <span>Riaz Sagar</span> ,Logichunt Inc.</h4>
                                        </div>
                                    </Accordion.Header>
                                    <Accordion.Body className="body">
                                        <p className="pera">
                                            Lorem, ipsum dolor sit amet consectetur adipisicing elit. Repudiandae, adipisci.
                                            Lorem, ipsum dolor sit amet consectetur adipisicing elit. Repudiandae, adipisci.
                                        </p>
                                        <h4 className="location">
                                            <span>Location:</span> Hall 1, Building A, Golden Street, <span>Southafrica</span>
                                        </h4>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}
