import { toAbsoluteUrl } from '../../../_metronic/helpers'
import Button from '../button/Button'
import './bannersection.scss'

export default function BannerSection() {
    return (
        <section className="banner_section">
            <div className="container-fluid p-0">
                <div className="row g-0">
                    <div className="col-12 col-md-6">
                        <div className="banner_text">
                            <div className="text_box">
                                <h2>Learn With The <br /> Best Online School.</h2>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                            </div>
                            <Button title={'GET THE COURSE'} link='/'/>
                        </div>
                    </div>
                    <div className="col-12 col-md-6">
                        <div className="imgbox">
                            <img
                                alt='Logo'
                                src={'/banner.jpg'}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
