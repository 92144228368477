import { FaArrowRightLong } from "react-icons/fa6";
import './coursedetails.scss'
import Rating from '../rating/Rating'
import RelatedCourses from '../related-courses/RelatedCourses';
import { useState } from 'react';
import { FaLongArrowAltRight, FaRegPlayCircle, FaLock, FaPlay, FaStar } from "react-icons/fa";
import { MdAccessTime } from "react-icons/md";
import { Accordion } from 'react-bootstrap';

export default function CourseDetails() {
    const [tabIndex, setTabIndex] = useState<number>(1);
    return (
        <>
            <section className='course_banner_section'>
                <div className="container-fluid">
                    <div className="imgbox">
                        <img src="/blog1.jpg" alt="" />
                    </div>
                </div>
            </section>
            <section className='course_details_main_section'>
                <div className='container'>
                    <div className='row'>
                        <div className="col-12 order-2 col-lg-8 order-lg-1">
                            <h2 className="course_title">Java programming</h2>

                            <div className="logo_with_list_box">
                                <div className="logo">
                                    <div className="imgbox">
                                        <img src="/profile1.jpg" alt="" />
                                    </div>
                                </div>

                                <ul className="list">
                                    <li>
                                        <span>Teacher</span>
                                        <span className="bold">Jhon Kanel</span>
                                    </li>
                                    <li>
                                        <span>Teacher</span>
                                        <span className="bold">Jhon Kanel</span>
                                    </li>
                                    <li>
                                        <span>Teacher</span>
                                        <span className="bold">Jhon Kanel</span>
                                    </li>
                                </ul>
                            </div>

                            <div className="tab_trigger_box">
                                <button
                                    className={`tab_trigger ${tabIndex === 1 && 'active'}`}
                                    onClick={() => setTabIndex(1)}
                                >
                                    Overview
                                </button>
                                <button
                                    className={`tab_trigger ${tabIndex === 2 && 'active'}`}
                                    onClick={() => setTabIndex(2)}
                                >
                                    Overview
                                </button><button
                                    className={`tab_trigger ${tabIndex === 3 && 'active'}`}
                                    onClick={() => setTabIndex(3)}
                                >
                                    Overview
                                </button><button
                                    className={`tab_trigger ${tabIndex === 4 && 'active'}`}
                                    onClick={() => setTabIndex(4)}
                                >
                                    Overview
                                </button>
                            </div>

                            <div className="tab_data_box">
                                <div className={`tab_data ${tabIndex === 1 && 'active'}`}>
                                    <div className="descriptions">
                                        <h2 className="heading">Course description</h2>
                                        <p className="pera">
                                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Q
                                            uod enim corrupti id aspernatur quibusdam porro optio numquam
                                            tempore omnis qui, eius magni fugiat quam, expedita distinctio,
                                            reiciendis dolor ipsum aliquam?
                                        </p>
                                        <h2 className="heading">Course description</h2>

                                        <ul className="targets">
                                            <li>
                                                <FaLongArrowAltRight />
                                                Be able to use simple tricks
                                            </li>
                                            <li>
                                                <FaLongArrowAltRight />
                                                Be able to use simple tricks
                                            </li>
                                            <li>
                                                <FaLongArrowAltRight />
                                                Be able to use simple tricks
                                            </li>
                                            <li>
                                                <FaLongArrowAltRight />
                                                Be able to use simple tricks
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <div className={`tab_data ${tabIndex === 2 && 'active'}`}>
                                    <div className="curriculum">
                                        <p className="pera">
                                            Lorem, ipsum dolor sit amet consectetur
                                            adipisicing elit. Cum doloremque voluptatibus
                                            accusamus voluptas animi non ipsum repellat
                                            tempora. Animi alias aliquam eius quam,
                                            porro cum aut ex harum ipsa optio!
                                        </p>

                                        <Accordion defaultActiveKey="0">
                                            <Accordion.Item className="accordion_item" eventKey="0">
                                                <Accordion.Header className="head">
                                                    <h2 className="title">Java programming</h2>
                                                </Accordion.Header>
                                                <Accordion.Body className="body">
                                                    <ul className="list">
                                                        <li>
                                                            <div className="left_box">
                                                                <h2 className="title">
                                                                    <span>02</span>
                                                                    <FaRegPlayCircle />
                                                                    Introduction Of Java
                                                                </h2>
                                                            </div>
                                                            <div className="right_box">
                                                                <button>Preview</button>
                                                                <span className="lock">
                                                                    <FaLock />
                                                                </span>
                                                                <span className="duration">
                                                                    <MdAccessTime />
                                                                    19 minutes
                                                                </span>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion>
                                    </div>
                                </div>

                                <div className={`tab_data ${tabIndex === 3 && 'active'}`}>
                                    <div className="instructor">
                                        <h2 className="title">Instructor</h2>

                                        <a href="#">
                                            <h2 className="name">Logus Academy</h2>
                                        </a>
                                        <h3 className="subname">Online School of the Future</h3>

                                        <div className="profile_box">
                                            <div className="imgbox">
                                                <img src="/banner.jpg" alt="" />
                                            </div>

                                            <ul className="list">
                                                <li>
                                                    <FaStar />
                                                    4.5 Instructor Rating
                                                </li>
                                                <li>
                                                    <FaStar />
                                                    4.5 Instructor Rating
                                                </li>
                                                <li>
                                                    <FaStar />
                                                    4.5 Instructor Rating
                                                </li>
                                                <li>
                                                    <FaStar />
                                                    4.5 Instructor Rating
                                                </li>
                                            </ul>
                                        </div>

                                        <p className="pera">
                                            Lorem ipsum dolor, sit amet consectetur
                                            adipisicing elit. Molestias et laboriosam
                                            debitis itaque maiores voluptas officia
                                            aperiam doloribus at, facilis error qui,
                                            saepe beatae aspernatur illo alias corrupti,
                                            cumque non consequatur similique reprehenderit
                                            velit optio ipsam? Totam doloribus voluptate magnam.
                                        </p>

                                        <a href="#" className="show_more">Show more</a>
                                    </div>
                                </div>

                                <div className={`tab_data ${tabIndex === 4 && 'active'}`}>
                                    <div className="reviews">
                                        <div className="rating_content">
                                            <div className="rating_square">
                                                <h2 className="rating_text">4.9</h2>
                                                <Rating ratings={4} />
                                                <p className="pera">1,455 Ratings</p>
                                            </div>

                                            <div className="rating_bars_box">
                                                <div className="rating_bar">
                                                    <div className="top_box">
                                                        <p className="star_count">5 Star</p>
                                                        <p className="total_count">13</p>
                                                    </div>
                                                    <div className="bar_outer"><div className="bar_inner"></div></div>
                                                </div>
                                                <div className="rating_bar">
                                                    <div className="top_box">
                                                        <p className="star_count">5 Star</p>
                                                        <p className="total_count">13</p>
                                                    </div>
                                                    <div className="bar_outer"><div className="bar_inner"></div></div>
                                                </div>
                                                <div className="rating_bar">
                                                    <div className="top_box">
                                                        <p className="star_count">5 Star</p>
                                                        <p className="total_count">13</p>
                                                    </div>
                                                    <div className="bar_outer"><div className="bar_inner"></div></div>
                                                </div>
                                                <div className="rating_bar">
                                                    <div className="top_box">
                                                        <p className="star_count">5 Star</p>
                                                        <p className="total_count">13</p>
                                                    </div>
                                                    <div className="bar_outer"><div className="bar_inner"></div></div>
                                                </div>
                                                <div className="rating_bar">
                                                    <div className="top_box">
                                                        <p className="star_count">5 Star</p>
                                                        <p className="total_count">13</p>
                                                    </div>
                                                    <div className="bar_outer"><div className="bar_inner"></div></div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="reviewer_content">
                                            <div className="profile_box">
                                                <div className="imgbox">
                                                    <img src="/about.jpg" alt="" />
                                                </div>
                                                <div className="text_box">
                                                    <div className="title_and_time">
                                                        <h2 className="title">Mira Jone</h2>
                                                        <p className="time">15 December, 2023</p>
                                                    </div>
                                                    <Rating ratings={5}/>
                                                    <p className="pera">
                                                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                                                        Aliquid enim reiciendis, eius labore autem odit in temporibus
                                                        repellendus qui. Odit.
                                                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                                                        Aliquid enim reiciendis, eius labore autem odit in temporibus
                                                        repellendus qui. Odit.
                                                    </p>
                                                </div>
                                            </div>

                                            <div className="profile_box">
                                                <div className="imgbox">
                                                    <img src="/about.jpg" alt="" />
                                                </div>
                                                <div className="text_box">
                                                    <div className="title_and_time">
                                                        <h2 className="title">Mira Jone</h2>
                                                        <p className="time">15 December, 2023</p>
                                                    </div>
                                                    <Rating ratings={5}/>
                                                    <p className="pera">
                                                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                                                        Aliquid enim reiciendis, eius labore autem odit in temporibus
                                                        repellendus qui. Odit.
                                                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                                                        Aliquid enim reiciendis, eius labore autem odit in temporibus
                                                        repellendus qui. Odit.
                                                    </p>
                                                </div>
                                            </div>

                                            <div className="profile_box">
                                                <div className="imgbox">
                                                    <img src="/about.jpg" alt="" />
                                                </div>
                                                <div className="text_box">
                                                    <div className="title_and_time">
                                                        <h2 className="title">Mira Jone</h2>
                                                        <p className="time">15 December, 2023</p>
                                                    </div>
                                                    <Rating ratings={5}/>
                                                    <p className="pera">
                                                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                                                        Aliquid enim reiciendis, eius labore autem odit in temporibus
                                                        repellendus qui. Odit.
                                                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                                                        Aliquid enim reiciendis, eius labore autem odit in temporibus
                                                        repellendus qui. Odit.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 order-1 col-lg-4 order-lg-2">
                            <div className="card course_description_card">
                                <div className="head">
                                    <div className="imgbox">
                                        <img src="/blog1.jpg" alt="" />
                                        <button className="play_button">
                                            <FaPlay />
                                        </button>
                                    </div>
                                    <div className="price_box">
                                        <h2 className="price">$32</h2>
                                        <div className="ratingbox">
                                            <Rating ratings={4} />
                                            <span>4.8(867)</span>
                                        </div>
                                    </div>
                                </div>

                                <div className="body">
                                    <ul className="course_details_list">
                                        <li>
                                            <span>
                                                <FaArrowRightLong />
                                                Lectures
                                            </span>
                                            <span>8</span>
                                        </li>
                                        <li>
                                            <span>
                                                <FaArrowRightLong />
                                                Lectures
                                            </span>
                                            <span>8</span>
                                        </li>
                                        <li>
                                            <span>
                                                <FaArrowRightLong />
                                                Lectures
                                            </span>
                                            <span>8</span>
                                        </li>
                                        <li>
                                            <span>
                                                <FaArrowRightLong />
                                                Lectures
                                            </span>
                                            <span>8</span>
                                        </li>
                                    </ul>

                                    <button className="enroll_button">Enroll Now</button>
                                </div>
                            </div>

                            <div className="categories_box">
                                <div className="title">Course Categories</div>
                                <ul className="category_list">
                                    <li>
                                        <span>java Programming</span>
                                        <span className="bold">23</span>
                                    </li>
                                    <li>
                                        <span>java Programming</span>
                                        <span className="bold">23</span>
                                    </li>
                                    <li>
                                        <span>java Programming</span>
                                        <span className="bold">23</span>
                                    </li>
                                    <li>
                                        <span>java Programming</span>
                                        <span className="bold">23</span>
                                    </li>
                                    <li>
                                        <span>java Programming</span>
                                        <span className="bold">23</span>
                                    </li>
                                </ul>
                            </div>

                            <RelatedCourses />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
