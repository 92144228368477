import './meetsection.scss'

export default function MeetSection() {
    return (
        <section id="joinCommunity" className="meet_section">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-md-6">
                        <div className="meet_text">
                            <h2>
                                Meet
                                <span>Pilar Guerrero</span>,
                                Lorem Ipsum is simply dummy
                                text of the printi
                            </h2>
                            <a href="">Get the course</a>
                        </div>
                    </div>
                    <div className="col-12 col-md-6">
                        <div className="meet_img">
                            <div className="imgbox img1">
                                <img
                                    src="/meet1.jpg"
                                    alt="img"
                                />
                            </div>
                            <div className="imgbox img2">
                                <img
                                    src="/meet2.jpg"
                                    alt="img"
                                />
                            </div>
                            <div className="imgbox img3">
                                <img
                                    src="/meet3.jpg"
                                    alt="img"
                                />
                            </div>
                            <div className="imgbox img4">
                                <img
                                    src="/meet4.jpg"
                                    alt="img"
                                />
                            </div>
                            <div className="imgbox img5">
                                <img
                                    src="/meet5.jpg"
                                    alt="img"
                                />
                            </div>
                            
                            <div className="community_text">
                                <h3>10k</h3>
                                <p>Students Community</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
